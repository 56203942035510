import { Alert, Box, Button, Card, CardContent, CardHeader, Grid, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import React,{
    useState,
    useEffect,
} from 'react'
import { useCookies } from 'react-cookie';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import uuid from 'react-uuid';
import { ShipmentHistoryButton } from './Checkout';

const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const ShipmentSearchTool = ({posConfig}) => {
    const [temp, setTemp] = useState("");
    const [search, setSearch] = useState(false)

    const [loading, setLoading] = useState(false)
      
    const [sortDir, setSortDir] = useState('ASC');
    const [sortKey, setSortKey] = useState(false);
    
    const handleSort = (key) => {
      if(key === sortKey){
        setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC')
      }else{
        setSortKey(key)
        setSortDir('ASC')
      }
  
    }
    const [lastRequestId, setLastRequestId] = useState(0)
    const [searchResults, setSearchResults] = useState([])
  

    const [cookies] = useCookies(['secret', 'station', 'staff'])
  
    const { sendMessage, readyState } = useWebSocket(socketUrl, 
      {
        queryParams: {
          station: posConfig.connection.station,
          secret: cookies.secret,
          staff: cookies.staff
        },
        onMessage: (message) => handleWebsocketEvent(message),
        shouldReconnect: (_closeEvent) => true,
        retryOnError: true,
        onClose: () => console.log('closing'),
        onError: (err) => console.log('error', err),
        onReconnectStop: (err) => console.log('recon stop', err),
      }
    );
  
    const handleWebsocketEvent = (lastMessage) => {
  
        const msg = JSON.parse(lastMessage.data)
    
        if(msg.task==='respSearchShipmentByTerm'){

            const {results, requestId} = msg

            if(requestId === lastRequestId){
                setLoading(false)
            }

            setSearchResults(results.map(e=>({
                
                    ...e,
                    matchId: e.id.toLowerCase().indexOf(search.toLowerCase()) > -1,
                    matchAbsender: e.absender.toLowerCase().indexOf(search.toLowerCase()) > -1,
                    matchSendungsnummer: e.sendungsnummer.toLowerCase().indexOf(search.toLowerCase()) > -1,
                
            })))
        }
      
    }
  
    const searchShipmentByTerm = (searchTerm, thisRqId) => {
      sendMessage(JSON.stringify({task: 'searchShipmentByTerm', station: posConfig.connection.station, staff: cookies.staff, searchTerm, requestId: thisRqId, sortDir, sortKey}))
    }
    
    useEffect(()=>{
        

        const timeOutId = setTimeout(() => {
          if(temp === ""){
            setSearch(false)
            setSearchResults([])
          }else{
            setSearch(temp)
            setLoading(true)
            const thisRqId = uuid()
            setLastRequestId(thisRqId)
            searchShipmentByTerm(temp, thisRqId)
          }
        }, 250);
        return () => clearTimeout(timeOutId);
    },[temp, sortDir, sortKey])

    const keys = [
        'creationdate',
        'kdnr',
        'display_name',
        'klasse',
        'lager_name',
        'sendungsnummer',
        'status',
        'abholen_bis',
        'abholdatum2',
        'ruecksendedatum',
        'absender',
        'spedition',
        'abholerText'
    ]





    return (
        <>
            <Paper sx={{mb: 2, p: 4}}>
                <TextField label="Suche nach Sendungen mit Absender, Sendungsnummer oder MPSID" sx={{width:'100%'}} autoComplete='false' onChange={(evt)=>setTemp(evt.target.value)} />
            </Paper>
            
            { false && <pre>{JSON.stringify(searchResults, null, 2)}</pre> }
            { false && <MyCell /> }

            { loading && <LinearProgress /> }
            
            { !! searchResults.length && 
                <TableContainer component={Paper} sx={{mb: 1}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                              <TableCell>id <IconButton onClick={()=>handleSort('id')}><SortIcon /></IconButton></TableCell>
                                {keys.map((e,i)=>{
                                    return <TableCell key={i}>{e}<IconButton onClick={()=>handleSort(e)}><SortIcon /></IconButton></TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.map((e,i)=>{
                                return (
                                    <TableRow key={i}>
                                        <TableCell><Box sx={{display:'flex'}}><HighlightTerm text={e['id']} term={search} /> <ShipmentHistoryButton id={e.id} /></Box></TableCell>
                                        {keys.map((ee,i)=>{
                                            return <TableCell key={i}><HighlightTerm text={e[ee]} term={search} /></TableCell>
                                        })}
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default ShipmentSearchTool


const MyCell = () => {


    return (
        <Card sx={{mb: 1}}>
            <CardContent>
                <Typography>12389726389792 | Vincent van den Eijkel (15312)</Typography>
                <Grid container>
                    <Grid item md={4}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Lieferung:</TableCell>
                                    <TableCell><b>10.02.2023</b> von <b>DHL</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Abholung:</TableCell>
                                    <TableCell><b>14.02.2023</b> von <b>15312 Vincent van den Eijkel</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        
                        
                        
                    </Grid>
                    <Grid item md={6}>
                        <Table size="small" >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Sendungsnummer:</TableCell>
                                    <TableCell><HighlightTerm text="J001234897239847923874" term="J00123" /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Absender:</TableCell>
                                    <TableCell>Amazon SARL EU Düsseldorf</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item md={3}>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

}



const HighlightTerm = ({text, term}) => {

    if(typeof text !== "string") return text
    const beforeIndex = text.toLocaleLowerCase().indexOf(term.toLocaleLowerCase())
    
    if(beforeIndex === -1) return text;

    const afterIndex = beforeIndex + term.length
    const before = text.slice(0,beforeIndex)
    const after = text.slice(afterIndex)

    return <>{before}<Box component="span" sx={{backgroundColor:'lightyellow'}}>{term}</Box>{after}</>
}

/*

const SearchField = ({posConfig, onSelect}) => {

    const [temp, setTemp] = useState("");
    const [search, setSearch] = useState(false)
  
    const [mode, setMode] = useState("kdnr")
  
    const [lastRequestId, setLastRequestId] = useState(0)
    const [searchResults, setSearchResults] = useState([])
  
  
  
    const [cookies] = useCookies(['secret', 'station', 'staff'])
  
    const { sendMessage, readyState } = useWebSocket(socketUrl, 
      {
        queryParams: {
          station: posConfig.connection.station,
          secret: cookies.secret,
          staff: cookies.staff
        },
        onMessage: (message) => handleWebsocketEvent(message),
        shouldReconnect: (_closeEvent) => true,
        retryOnError: true,
        onClose: () => console.log('closing'),
        onError: (err) => console.log('error', err),
        onReconnectStop: (err) => console.log('recon stop', err),
      }
    );
  
    const handleWebsocketEvent = (lastMessage) => {
  
      const msg = JSON.parse(lastMessage.data)
  
      if(msg.task==='respSearchKdnrByTerm'){
        const {results, requestId} = msg
        if(requestId === lastRequestId){
          setSearchResults(results)
        }
      }
    }
  
    const searchKdnrByTerm = (searchTerm, thisRqId) => {
      sendMessage(JSON.stringify({task: 'searchKdnrByTerm', station: posConfig.connection.station, staff: cookies.staff, searchTerm, requestId: thisRqId}))
    }
    
    useEffect(()=>{
      
  
      const timeOutId = setTimeout(() => {
        if(temp === ""){
          setMode('kdnr')
          setSearch(false)
          setSearchResults([])
        }else{
          setMode('name')
          setSearch(temp)
          const thisRqId = uuid()
          setLastRequestId(thisRqId)
          searchKdnrByTerm(temp, thisRqId)
        }
      }, 250);
      return () => clearTimeout(timeOutId);
    },[temp])
  
    const handlerKeyPress = (evt) => {
      if(evt.keyCode === 13){
        if( ! isNaN(temp)){
          onSelect(temp)
        }
     }
    }
    const kdnrInput = useRef(null)
  
    useEffect(()=>{
      kdnrInput.current.focus()
    },[])
  
    return (
        <Paper sx={{mb: 2, p: 4}} onMouseEnter={()=>{kdnrInput.current.focus()}}>
      
        <Autocomplete
          disablePortal
          autoHighlight
          freeSolo
          id="combo-box-kdnr-searchterm"
          options={searchResults.map(e=>({
            label: e.ID + ' - ' + e.display_name + ' (' + e.user_email + ')',
            id: e.ID
          }))}
          sx={{ width: '100%' }}
          value={temp}
          onInputChange={(evt, value, id)=>{setTemp(value)}}
          onChange={(a,b)=>{
            if(b?.id){
              onSelect(b.id)
            }
          }}
          renderInput={(params) => <TextField variant="outlined" inputRef={kdnrInput} {...params} label="Suche nach Kunde" />}
          onKeyDown={handlerKeyPress}
          noOptionsText={"Suche nach Name oder Email"}
        />
      </Paper>
    )
  }

  */