import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import { Box, Chip, Paper, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { East, LocalShipping } from '@mui/icons-material';
import InputIcon from '@mui/icons-material/Input';


const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const ShipmentsJournal = ({ posConfig }) => {


    const [data, setData] = useState();

    const now = new Date();
    const [dateString, setDateString] = useState([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('#'));

    const [cookies] = useCookies(['secret', 'station', 'staff'])

    useEffect(() => {
        getJournal();
    }, [dateString]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetShipmentJournal') {

            const { records, staffInfo, warehouseInfo } = msg;
            setData({ records, staffInfo, warehouseInfo });

        };

    };

    const getJournal = () => {
        sendMessage(JSON.stringify({ task: 'getShipmentJournal', station: posConfig.connection.station, staff: cookies.staff, dateString }))
    }



    if (!data?.records) return;


    const dids = data.records.reduce((p, c) => {
        if (!p.includes(c.did)) {
            p.push(c.did);
        }
        return p;
    }, [])

    const staffMap = data.staffInfo.reduce((p,c)=>{
        p[c.id] = c.vorname + ' ' + c.nachname;
        return p;
    },{});

    const warehouseMap = data.warehouseInfo.reduce((p,c)=>{
        p[c.id] = c.text;
        return p;
    },{});

    
    return (
        <>
            <Paper>
                {dids.map((did, i) => {
                    let lastTimestamp = false;
                    return (
                        <Paper kex={i}>
                            <Typography>DID {did}</Typography>
                            <Timeline position="left">
                            {data.records.filter(ee => ee.did === did).sort((a,b)=>new Date(a.timestamp).getTime()-new Date(b.timestamp).getTime()).map((ee, ii) => {

                                    const ts = new Date(ee.timestamp).getTime();
                                    const timeDiff = lastTimestamp ? ts-lastTimestamp : false;
                                    lastTimestamp = ts;

                                    return (<>
                                        <TimelineItem key={ii}>
                                            <TimelineOppositeContent>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                
                                                    <Box component="small" sx={{width:'36px', textAlign:'center', color:'gray', fontSize: '11px'}}>{(timeDiff/1000).toFixed(0)}s</Box>
                                                
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem key={ii}>
                                            <TimelineOppositeContent sx={{pt:3}}>
                                                
                                                {new Date(ee.timestamp).toLocaleString('de-DE')} 
                                                <Typography variant="caption" component="p">{staffMap[ee.staff]} {ee.did && <>({ee.did})</>}</Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    {getActionIcon(ee.action)}
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{py: 2}}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                    {getActionName(ee.action)}
                                                </Typography>
                                                <Typography>{ee.mpsid}</Typography>
                                                {ee.action === 'CHECKIN' && <Chip label={<LocalShipping sx={{fontSize:'16px'}} />} color="default" size='small' /> }
                                                {ee.action !== 'CHECKIN' && <Chip label={warehouseMap[ee.previous_lager_id]} color="default" size='small' /> }
                                                <East sx={{fontSize:'16px'}} /> <Chip label={warehouseMap[ee.lager_id]} color="default" size='small' />
                                            </TimelineContent>
                                        </TimelineItem>
                                        </>
                                    );
                                })}
                            </Timeline>
                        </Paper>
                    );
                })}

            </Paper>
        </>
    );
};

export default ShipmentsJournal;






const getActionName = (key) => {
    return { 'CHECKIN': 'Erfassung', 'RELOCATE': 'Ein-/Umlagerung', 'CHECKOUT': 'Ausgabe' }[key];
}

const getActionIcon = (key) => {
    return { 'CHECKIN': <LocalShipping />, 'RELOCATE': <InputIcon />, 'CHECKOUT': 'Ausgabe' }[key];
}