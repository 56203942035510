import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';


const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const SelfCheckoutManager = ({ posConfig }) => {


    const [cookies] = useCookies(['secret', 'station', 'staff']);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff,
                type: 'sc.pickup'
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const scManagerAvailable = () => {
        sendMessage(JSON.stringify({ task: 'scManagerAvailable', station: posConfig.connection.station, staff: cookies.staff, secret: cookies.secret }))
    };

    const scManagerUnavailable = () => {
        sendMessage(JSON.stringify({ task: 'scManagerUnavailable', station: posConfig.connection.station, staff: cookies.staff }))
    };

    const scManagerTaskRequestConfirmation = (taskId) => {
        sendMessage(JSON.stringify({ task: 'scManagerTaskRequestConfirmation', station: posConfig.connection.station, staff: cookies.staff, taskId }))
    };

    const scManagerTaskConfirmation = (taskId) => {
        sendMessage(JSON.stringify({ task: 'scManagerTaskConfirmation', station: posConfig.connection.station, staff: cookies.staff, taskId }))
    };

    const [pending, setPending] = useState(false);
    const [taskData, setTaskData] = useState();
    const [stationStatus, setStationStatus] = useState('UNAVAILABLE');

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data);

        if(msg.task === 'respScManagerAvailable'){
            if(msg.currentTask){
                setTaskData(msg.currentTask);
            }
            setStationStatus(msg.stationStatus);
        }

        if (msg.task === 'scManagerTaskRequest') {
            setPending(true);
            scManagerTaskRequestConfirmation(msg.taskId);
            setStationStatus(msg.stationStatus);
        }

        if (msg.task === 'scManagerTask') {
            scManagerTaskConfirmation(msg.taskId);
            setStationStatus(msg.stationStatus);
        }
    };

    useEffect(() => {
        scManagerAvailable();
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Paper sx={{ p: 2 }}>
                    Status: {stationStatus}
                </Paper>
                <Paper sx={{ p: 2 }}>
                    
                </Paper>
            </Box>
        </>
    );
};

export default SelfCheckoutManager;