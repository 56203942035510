import { Box, Checkbox, Alert, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Select, MenuItem } from '@mui/material';
import { Container } from '@mui/system';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import axios from 'axios'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

const Admin = () => {

    const [googleCredentials, setGoogleCredentials] = useState(false)
    const [view, setView] = useState('HOME')
    const [cookies, setCookie] = useCookies(['gcred']);



    return (
        <>
            <GoogleOAuthProvider clientId="804350679829-aug0ld4lmvjrk6q21ps02uau4d4bpk3p.apps.googleusercontent.com">
                <Container sx={{ height: '80px' }}>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            setGoogleCredentials(credentialResponse)
                            setCookie('gcred', JSON.stringify(credentialResponse))
                            setView('LOGGED_IN')
                        }}
                        onError={() => {
                            setView('WRONG_PASSWORD')
                            console.log('Login Failed');
                        }}
                        auto_select
                    />
                </Container>
                {view === 'WRONG_PASSWORD' && <Typography>Login fehlgeschlagen.</Typography>}
                {view === 'LOGGED_IN' && <AdminLoggedIn googleCredentials={googleCredentials} />}
            </GoogleOAuthProvider>
        </>
    )

}

export default Admin


const AdminLoggedIn = ({ googleCredentials }) => {

    const [data, setData] = useState('LOADING');
    const [refresh, setRefresh] = useState(1);
    const { auth } = useParams();

    useEffect(() => {
        console.log({ googleCredentials })
        const fetchData = async () => {
            const response = await axios.get('https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod/checkoutapp/stations', {
                headers: {
                    authorization: googleCredentials.credential
                }
            })
            console.log(response)
            setData(response.data)
        }

        if (googleCredentials) {
            fetchData();
        }

    }, [googleCredentials, refresh])


    const handlerDelete = async (secret) => {

        await axios.delete('https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod/checkoutapp/stations', {
            headers: {
                authorization: googleCredentials.credential
            },
            data: {
                secret
            }
        })

        setRefresh(refresh + 1)
    }



    return (
        <>
            {data !== 'LOADING' && <>
                <Authorizer stations={data} googleCredentials={googleCredentials} refresh={() => { setRefresh(refresh + 1) }} />
                {((auth && auth.slice(0, 5) === 'XMPSA')===false) &&
                    <Container sx={{ mb: 2 }}>
                        {!data.length && <Alert sx={{ mb: 2 }} severity="info">Keine POS Endgeräte registriert.</Alert>}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>POS Endgerät</b> <Button onClick={() => { setRefresh(refresh + 1) }}>Refresh</Button></TableCell>
                                        <TableCell><b>Aktion</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{e.name} ({e.date})</TableCell>
                                                <TableCell><Button variant="contained" color="error" onClick={() => handlerDelete(e.secret)}>Löschen</Button></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                }

            </>}
            {data === 'LOADING' && <>Loading...</>}
        </>
    )

}


const Authorizer = ({ googleCredentials, refresh, stations }) => {

    const navigate = useNavigate()

    const [cookies] = useCookies(['gcred']);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [station, setStation] = useState("NONE");

    const { auth } = useParams();

    const handleAuthorize = () => {

        const authorizePosStation = async () => {

            await axios.post('https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod/checkoutapp/stations', {
                secret: auth,
                name,
                type,
                station
            }, {
                headers: {
                    authorization: googleCredentials.credential
                }
            })
        }

        if (!name && station === "NONE") return alert('Bitte zuerst Name vergeben')
        if (!cookies.gcred) return alert('Bitte zuerst mit Google anmelden')

        authorizePosStation();

        refresh()
        navigate("/admin")

    };



    if (auth && auth.slice(0, 5) === 'XMPSA') {

        return (
            <Container sx={{ mt: 4, mb: 2 }}>
                <Paper sx={{display: 'flex', flexDirection: 'column', gap: 2, p:2}}>
                    <Typography variant="h5" gutterBottom>Station autorisieren:</Typography>
                    <Alert severity='info'>
                        Um ein bestehendes Gerät zu ersetzen kann dieses in der Liste ausgewählt werden.<br />
                        Alternativ kann ein Name eingegeben werden um eine neue Station anzulegen.
                    </Alert>
                    <Select value={station} onChange={(evt) => setStation(evt.target.value)} fullWidth>
                        <MenuItem value={"NONE"}>NEUE STATION</MenuItem>);
                        {stations.map((e, i) => {
                            return (<MenuItem key={i} value={e.secret}>{e.name}</MenuItem>);
                        })}
                    </Select>
                    {station === "NONE" && <TextField fullWidth onChange={(evt) => { setName(evt.target.value) }} label="Name der Station" sx={{ mr: 1 }} />}
                    <Box>
                        <Checkbox onClick={(evt) => { setType(evt.target.checked ? "MONITOR" : "DEFAULT") }} /> Lager-Monitor-App
                    </Box>
                    <Button fullWidth color="success" variant="contained" onClick={handleAuthorize}>Registrieren</Button>
                </Paper>
            </Container>
        )

    } else {
        return null;
    }

}