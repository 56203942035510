import { CheckBox, Favorite, Star } from '@mui/icons-material';
import { Box, Button, Checkbox, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';


const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const DeviceManagement = ({ posConfig }) => {


    const [cookies] = useCookies(['secret', 'station', 'staff']);

    const [devices, setDevices] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(0);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const getStations = (slot, mpsid) => {
        sendMessage(JSON.stringify({ task: 'getStations', station: posConfig.connection.station, staff: cookies.staff }))
    };

    const updateStation = (id, values) => {
        sendMessage(JSON.stringify({ task: 'updateStation', station: posConfig.connection.station, staff: cookies.staff, id, values }))
    };


    const handleWebsocketEvent = (lastMessage) => {

        const namesorter = (a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }
        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respUpdateStation') {
            setDevices(msg.stations.sort(namesorter));
            setProducts(msg.products);
        }

        if (msg.task === 'respGetStations') {
            setDevices(msg.stations.sort(namesorter));
            setProducts(msg.products);
        }

    };

    useEffect(() => {
        getStations();
    }, [])



    const handleChange = (v) => {
        setEditData(null);
        setSelectedDevice(v)
    };

    const tableRows = [
        { label: 'Name', key: 'name', editable: true },
        { label: 'Erstellt am', key: 'date', render: (({ v }) => new Date(v).toLocaleString('de-DE')) },
        { label: 'Zuletzt angemeldet', key: 'lastseen', render: (({ v }) => new Date(v).toLocaleString('de-DE')) },
        { label: 'Letzter Verbindungskanal', key: 'laststation' },
        { label: 'Self-Checkout Modus', key: 'scmode', editable: true },
        { label: 'Einstellungen für Self-Checkout Ausgabe', key: 'scpickupsettings', render: (({ v }) => 'Einstellungen'), inputRender: EditScPickupSettings, editable: true },
        { label: 'Self Checkout SumUp Android Bridge', key: 'scsumupbridge', render: (({ v }) => v ? 'Ja' : 'Nein'), inputRender: (({ value, onChange }) => <Switch checked={!!value} onChange={(evt) => { onChange(evt.target.checked) }} />), editable: true },
        { label: 'Geräte-Art', key: 'devicetype', render: ({ v }) => v || 'DEFAULT' },
    ];


    const [editData, setEditData] = useState(null);

    const handlEditMode = () => {

        const editableRows = tableRows.filter(e => e.editable).map(e => e.key);

        const tmp = Object.keys(devices[selectedDevice]).reduce((p, c) => {
            if (editableRows.includes(c)) {
                p[c] = devices[selectedDevice][c];
                console.log({ pp: p[c], d: devices[selectedDevice][c] })
            }
            return p;
        }, {});

        setEditData(tmp);
    };

    const handlSaveEdit = () => {
        updateStation(devices[selectedDevice].secret, editData);
        setEditData(null);
    };



    return (
        <>
            <Typography variant='h5' gutterBottom>Geräteverwaltung</Typography>
            <Paper sx={{ p: 4, mt: 2 }}>
                <Box sx={{ display: 'flex', gap: 4 }}>
                    <Box sx={{ flex: 1 }}>
                        <Box
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                        >
                            <MenuList
                                sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                            >
                                {devices.map((e, i) => {
                                    return (
                                        <MenuItem key={i} value={i} onClick={() => handleChange(i)} selected={selectedDevice === i}>
                                            {e.secret === cookies.secret && <ListItemIcon>
                                                <Star />
                                            </ListItemIcon>}
                                            <ListItemText>{e.name}</ListItemText>

                                        </MenuItem>
                                    );
                                })}
                            </MenuList>

                        </Box>
                    </Box>
                    <Box sx={{ flex: 5 }}>
                        {devices[selectedDevice] && <>

                            <Typography variant="h6">{devices[selectedDevice].secret === cookies.secret ? <Box component="span" sx={{ display: 'flex' }}><Star sx={{ fontSize: '1.6rem' }} /> {devices[selectedDevice].name} [DIESES GERÄT]</Box> : <>{devices[selectedDevice].name}</>}</Typography>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {tableRows.map((e, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{e.label || e.key}</TableCell>
                                                    <TableCell>
                                                        {
                                                            (e.editable && editData !== null)
                                                                ? (e?.inputRender)
                                                                    ? e.inputRender({ products, value: editData[e.key], onChange: (val) => setEditData({ ...editData, [e.key]: val }) })
                                                                    : <TextField size='small' value={editData[e.key]} onChange={(evt) => setEditData({ ...editData, [e.key]: evt.target.value })} />
                                                                : (e?.render)
                                                                    ? e.render({ v: devices[selectedDevice][e.key] })
                                                                    : devices[selectedDevice][e.key]
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}
                        <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                            {editData === null && <Button variant="outlined" onClick={handlEditMode}>Bearbeiten</Button>}
                            {editData !== null && <Button variant='contained' color="error" onClick={() => setEditData(null)}>Änderungen Verwerfen</Button>}
                            {editData !== null && <Button variant="contained" color="success" onClick={handlSaveEdit}>Änderungen speichern</Button>}
                        </Box>
                        {false && JSON.stringify({ editData })}
                        {false && JSON.stringify(devices[selectedDevice])}
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default DeviceManagement;


const EditScPickupSettings = ({ value, onChange, products }) => {

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Erlaubte Sendungsklassen</TableCell>
                            <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                    {products.map((e, i) => {
                                        return (
                                            <Box sx={{ border: 'solid 1px gray', textAlign: 'center', pt: 1 }}>
                                                {e.Klasse}
                                                <Checkbox
                                                    key={i}
                                                    onClick={(evt) => {
                                                        const tmp = value || {};
                                                        if (evt.target.checked) {
                                                            tmp.allowedProducts = tmp?.allowedProducts ? [...tmp.allowedProducts.filter(ee => ee !== e.Klasse), e.Klasse] : [e.Klasse]
                                                        } else {
                                                            tmp.allowedProducts = tmp?.allowedProducts ? [...tmp.allowedProducts.filter(ee => ee !== e.Klasse)] : []
                                                        }
                                                        onChange(tmp);
                                                    }}
                                                    checked={value?.allowedProducts?.includes(e.Klasse)}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Erlaube Barzahlung</TableCell>
                            <TableCell>
                                <Checkbox
                                    onClick={(evt) => {
                                        const tmp = value || {};
                                        if (evt.target.checked) {
                                            tmp.allowCashpayment = true
                                        } else {
                                            tmp.allowCashpayment = false
                                        }
                                        onChange(tmp);
                                    }}
                                    checked={value?.allowCashpayment}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>

    );
};
