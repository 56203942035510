import { LinearProgressWithLabel, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import React,{
    useState,
    useEffect,
} from 'react'
import { useCookies } from 'react-cookie';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import uuid from 'react-uuid';

const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const ShipmentSearchWarehouse = ({posConfig}) => {
    const [temp, setTemp] = useState("");
    const [search, setSearch] = useState(false)

    const [loading, setLoading] = useState(false)
      
    const [sortDir, setSortDir] = useState('ASC');
    const [sortKey, setSortKey] = useState(false);
    
    const handleSort = (key) => {
      if(key === sortKey){
        setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC')
      }else{
        setSortKey(key)
        setSortDir('ASC')
      }
  
    }
    const [lastRequestId, setLastRequestId] = useState(0)
    const [searchResults, setSearchResults] = useState([])
  

    const [cookies] = useCookies(['secret', 'station', 'staff'])
  
    const { sendMessage, readyState } = useWebSocket(socketUrl, 
      {
        queryParams: {
          station: posConfig.connection.station,
          secret: cookies.secret,
          staff: cookies.staff
        },
        onMessage: (message) => handleWebsocketEvent(message),
        shouldReconnect: (_closeEvent) => true,
        retryOnError: true,
        onClose: () => console.log('closing'),
        onError: (err) => console.log('error', err),
        onReconnectStop: (err) => console.log('recon stop', err),
      }
    );
  
    const handleWebsocketEvent = (lastMessage) => {
  
        const msg = JSON.parse(lastMessage.data)
    
        if(msg.task==='respGetInventory'){

            const {results, requestId} = msg

            if(requestId === lastRequestId){
                setLoading(false)
            }

            setSearchResults(results)
        }
      
    }
  
    const getInventory = (thisRqId) => {
      sendMessage(JSON.stringify({task: 'getInventory', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId}))
    }
    
    
    useEffect(()=>{
        const thisRqId = uuid()
        setLastRequestId(thisRqId)
        getInventory(thisRqId)
    },[])

    const keys = [
        'Lager-Ort',
        'Auslastung',
        'Sendungen',
    ]


    return (
        <>
        <Typography variant="h3" gutterBottom>Lagerbestand</Typography>

            { loading && <LinearProgress /> }
            
            { !! searchResults.length && 
                <TableContainer component={Paper} sx={{mb: 1}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {keys.map((e,i)=>{
                                    return <TableCell key={i}>{e}<IconButton onClick={()=>handleSort(e)}><SortIcon /></IconButton></TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.map((e,i)=>{
                              const realUsage = Math.round(e.volUsage * 100)
                              const usage = realUsage > 100
                                ? 100
                                : realUsage

                                return (
                                    <TableRow key={i}>
                                      <TableCell key={i}>
                                        {e.text}
                                      </TableCell>
                                      <TableCell key={i}>
                                        <LinearProgress variant="determinate" value={usage} color={realUsage > 100 ? 'error' : 'primary' } />
                                        <Typography variant="caption">
                                          <b>{realUsage}%</b> ({Math.round(e.volUsage*100)/100} / {Math.round(e.volSum*100)/100})
                                        </Typography>
                                      </TableCell>
                                      <TableCell key={i}>{e.countShipments}</TableCell>
                                      
                                        
                                      
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default ShipmentSearchWarehouse




