import { Alert, Box, Button, Collapse, FormControlLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useWebSocket, { resetGlobalState } from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import { InfoOutlined } from '@mui/icons-material';
const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const SeparateEscrowAccountDashboard = ({ posConfig, setKdnr }) => {

    const [data, setData] = useState();

    const [cookies] = useCookies(['secret', 'station', 'staff']);
    const [lastRequestId, setLastRequestId] = useState(0);
    const [dateString, setDateString] = useState("20");

    const [status, setStatus] = useState('open');

    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    function addDay(days, d){
        setDateString(dateToYMD(new Date(d.setDate(d.getDate() + days))));
    }
    function addMonth(months, d){
        setDateString(dateToYMD(new Date(d.setMonth(d.getMonth() + months))).slice(0,7));
    }
    function addYear(years, d){
        setDateString(dateToYMD(new Date(d.setFullYear(d.getFullYear() + years))).slice(0,4));
    }


    useEffect(() => {
        setData();
        const thisRqId = uuid();
        setLastRequestId(thisRqId);
        getSeparateEscrowAccountReport({thisRqId, dateString});
    }, [dateString, status]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetSeparateEscrowAccountReport') {

            const { records, requestId } = msg;

            if (requestId === lastRequestId) {
                setData(records);
            };

        };

    };

    const getSeparateEscrowAccountReport = ({thisRqId, dateString}) => {
        sendMessage(JSON.stringify({ task: 'getSeparateEscrowAccountReport', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, status: status, dateString }))
    }

    const expedituresSum = data?.filter(e=>e.type==='expenditure')?.reduce((p, c) => {
        p = p + c.amount*1;
        return p;
    }, 0);

    const depositsSum = data?.filter(e=>e.type==='deposit')?.reduce((p, c) => {
        p = p + c.amount*1;
        return p;
    }, 0);

    const total = depositsSum - expedituresSum;

    return (
        <Paper sx={{ p: 4 }}>
            <Typography variant='h5'>Gesamtübersicht</Typography>
            <Paper variant="outlined" square sx={{p: 2, my: 2, display: 'flex', gap: 2}}>
                <Button onClick={()=>setDateString('20')}>Gesamt</Button>
                <Button onClick={()=>setDateString(dateToYMD(new Date()))}>Heute</Button>
                <Button onClick={()=>addDay(-1, new Date())}>Gestern</Button>
                <Button onClick={()=>addMonth(0, new Date())}>Dieser Monat</Button>
                <Button onClick={()=>addMonth(-1, new Date())}>Letzter Monat</Button>
                <Button onClick={()=>addYear(0, new Date())}>Dieses Jahr</Button>
                <Button onClick={()=>addYear(-1, new Date())}>Letztes Jahr</Button>
                <Button onClick={()=>addYear(-2, new Date())}>Älter</Button>
                <TextField size="small" value={dateString} onChange={(evt)=>setDateString(evt.target.value)} />
            </Paper>
            <Box sx={{ display: 'flex', gap: 4 }}>
                <Box sx={{ flex: 1 }}>
                    <Select value={status} onChange={(evt)=>setStatus(evt.target.value)}>
                        <MenuItem value="open">Offene Vorgänge</MenuItem>
                        <MenuItem value="closed">Geschlossene Vorgänge</MenuItem>
                    </Select>
                    {data && 
                        <>
                            {data && status==='open' && dateString === '20' && <Paper variant="outlined" square sx={{p: 2, my: 2}}>
                                <Typography>Auslagen: {formatCurrency(expedituresSum)}</Typography>
                                <Typography>Einlagen: {formatCurrency(depositsSum)}</Typography>
                                <Typography><b>Summe: {formatCurrency(total)}</b></Typography>
                                
                            </Paper>}
                            <RecordsList setKdnr={setKdnr} records={data?.sort((a,b)=>new Date(a.timestamp)-new Date(b.timestamp))} />
                        </>
                    }
                </Box>
            </Box>
        </Paper>
    );
};

export default SeparateEscrowAccountDashboard;



const RecordsList = ({ records, setKdnr }) => {

    if (!records?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{pl:0}}>Betrag</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>
                        <TableCell>Kunde</TableCell>
                        <TableCell>Variante</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((e, i) => {

                        const amount = e.type === 'deposit' ? e.amount : e.amount*-1;


                        const closedDetails = e?.closedDetails;

                        return (
                            <TableRow key={i}>
                                <TableCell sx={{pl:0}}>
                                    <Box component="span" sx={{color: getTypeColor(e.type)}}>{formatCurrency(amount)}</Box>
                                </TableCell>
                                <TableCell>{getTypeLabel(e)}</TableCell>
                                <TableCell>
                                    {e.comment}
                                    {closedDetails && <ClosedDetailsPopup data={JSON.parse(closedDetails)} />}
                                </TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>
                                <TableCell><Button variant="text" onClick={()=>setKdnr(e.kdnr)}>{e.kdnr}</Button></TableCell>
                                <TableCell>{e.variant}</TableCell>
                                
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ClosedDetailsPopup = ({data}) => {
    return (<Tooltip title={<Box component="ul">
    {data.map((e,i)=><Box component="li" key={i}>{e}</Box>)}
</Box>}><InfoOutlined /></Tooltip>);
};
const ExpenditureList = ({ expenditures }) => {

    if (!expenditures?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Betrag</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {expenditures.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>
                                
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const formatCurrency = (amount) => {
    return Intl.NumberFormat('de-DE', {style: "currency", currency: 'EUR'}).format(amount/100)
};


const getTypeColor = (type) => {
    switch (type) {
        case 'deposit': return 'darkgreen'
        case 'expenditure': return 'darkred'
        case 'withdrawal': return 'darkred'
        case 'notification': return 'darkgray'
        default: return 'darkgray'
    }
}
const getTypeLabel = (e) => {
    switch (e.type) {
        case 'deposit': return 'Einlage'
        case 'expenditure': return 'Auslage'
        case 'withdrawal': return 'Auszahlung (Rest/Einlage)'
        case 'notification': return 'Benachrichtigung ('+(e.flow === 'delay' ? 'Erneute Zustellung' : 'Ablehnung/Rücksendung')+')';
        default: return 'Unbekannt'
    }
}

export {getTypeColor, getTypeLabel}