import { Alert, Box, Button, Card, CircularProgress, Modal, Typography } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Signature = ({setProfiling, profiling, shipments, userdata, paymentMethod, secondPaymentMethod, consumedPrepaid, signatureName, manualScanLog}) => {

  const [messageHistory, setMessageHistory] = useState([]);
  const [image, setImage] = useState(false)
  const [awaitSignature, setAwaitSignature] = useState(false)
  
  const [cookies, setCookie] = useCookies(['secret, station']);

  const { sendMessage, readyState } = useWebSocket(socketUrl, 
    {
      queryParams: {
        station: cookies.station, 
        secret: cookies.secret
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err)
    }
  );


  const handleWebsocketEvent = (lastMessage) => {
    const data = JSON.parse(lastMessage.data);
    console.log({lastMessage})
    if(data.task === 'finish'){
      console.log('finish')
      setImage(data.image)
    }
  }
  
  
  const handleClickSendMessage = useCallback(() => 
  {
    setProfiling({
      type: 'checkout',
      loadCustomer: Date.now(),
      customerSigned: Date.now(),
    })
    
      sendMessage(JSON.stringify({task: 'sign', station: cookies.station, staff: cookies.staff, shipments, userdata, paymentMethod, secondPaymentMethod, consumedPrepaid, signatureName, manualScanLog, profiling}))
      setAwaitSignature(true)
    }
  , [sendMessage, shipments, userdata, paymentMethod, secondPaymentMethod, consumedPrepaid, signatureName, manualScanLog, cookies.station, cookies.staff, profiling]);

  const navigate = useNavigate()

  const handlerFinish = () => {
    navigate('/checkout');
  }


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <Box sx={{mb: 8}}>

      <Button
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
        variant="contained"
        color="primary"
        fullWidth
      >
        Unterschrift einholen
      </Button>

      <Modal open={awaitSignature} sx={{overflow:'scroll',maxHeight:'80%'}}>
        <Box sx={modalStyle}>
          { ! image && <>
            <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', mb: 4}}> 
              <CircularProgress sx={{mr: 2}}/>
              <Typography>
                Warten auf Unterschrift
              </Typography>
            </Box>
            <Button
              onClick={handleClickSendMessage}
              disabled={readyState !== ReadyState.OPEN}
              variant="outlined"
              color="primary"
              fullWidth
            >
              Retry
            </Button>
          </>
          }
          { !! image && <>
            
            <Box
              component="img"
              sx={{
                width: '400px'
              }}
              alt="sig"
              src={image}
            />
          
            <Alert>Erfolgreich verarbeitet!</Alert>
            <Button variant="contained" sx={{mt:2}} onClick={handlerFinish}>Fertig</Button>
          </>
          }
        </Box>
      </Modal>
      
      {/*<span>The WebSocket is currently {connectionStatus}</span>*/}
      

    </Box>
  );

}

export default Signature



const EscrowAccountSignature = () => {

  const [image, setImage] = useState(false)
  const [awaitSignature, setAwaitSignature] = useState(false)
  
  const [cookies, setCookie] = useCookies(['secret, station']);

  const [retryMessage, setRetryMessage] = useState();

  const { sendMessage, readyState } = useWebSocket(socketUrl, 
    {
      queryParams: {
        station: cookies.station, 
        secret: cookies.secret
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err)
    }
  );

  const handleWebsocketEvent = (lastMessage) => {
    const data = JSON.parse(lastMessage.data);
    console.log({lastMessage})
    if(data.task === 'finishEscrowPayout'){
      console.log('finish')
      setImage(data.image)
    }
    if(data.task === 'respSeparateEscrowAccountCheckout' && data.payedOut > 0){
      setRetryMessage(data);
      setAwaitSignature(true);
    }
    
  }
  


  const handleClickSendMessage = useCallback(() => 
  {
      console.log({retryMessage})
      sendMessage(JSON.stringify({...retryMessage, station: cookies.station, staff: cookies.staff, user: cookies.staff}))
      setAwaitSignature(true)
    }
  , [sendMessage, retryMessage, cookies.station, cookies.staff]);


  return (
    <Box sx={{mb: 2}}>

      <Modal open={awaitSignature} sx={{overflow:'scroll',maxHeight:'80%'}}>
        <Box sx={modalStyle}>
          { ! image && <>
            <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', mb: 4}}> 
              <CircularProgress sx={{mr: 2}}/>
              <Typography>
                Warten auf Unterschrift für Auszahlungs-Quittung
              </Typography>
            </Box>
            <Button
              onClick={()=>handleClickSendMessage()}
              disabled={readyState !== ReadyState.OPEN}
              variant="outlined"
              color="primary"
              fullWidth
            >
              Retry
            </Button>
          </>
          }
          { !! image && <>
            
            <Box
              component="img"
              sx={{
                width: '400px'
              }}
              alt="sig"
              src={image}
            />
          
            <Alert>Erfolgreich verarbeitet!</Alert>
            <Button variant="contained" sx={{mt:2}} onClick={()=>setAwaitSignature(false)}>Fertig</Button>
          </>
          }
        </Box>
      </Modal>
      
      {/*<span>The WebSocket is currently {connectionStatus}</span>*/}
      

    </Box>
  );

}

export {EscrowAccountSignature};