import { Typography, Box } from "@mui/material";

import ShipmentSearchTool from "./ShipmentSearchTool";

const ResearchHome = ({posConfig}) => {


  

    //<ScannerInput setValue={handlerChange} />
    //<Button onClick={handlerSubmit}>Suchen</Button>
    return (
  
      <>
        <Typography variant="h5" gutterBottom>Sendungsnummer</Typography>
        <Box>
          <ShipmentSearchTool posConfig={posConfig} />
        </Box>
      </>
    )
  }

  
  export default ResearchHome