import React, { useEffect, useRef, useState } from 'react';
import { Box, Menu, MenuItem, Button, TextField } from '@mui/material';

const ImageWithPolygons = ({ imageUrl, ocrResponse, updateDetection, setCustomerData, feedback, setFeedback }) => {
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [hoveredWord, setHoveredWord] = useState(null);
    const [hoveredLine, setHoveredLine] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedPolygon, setSelectedPolygon] = useState(null);

    useEffect(() => {
        if (!canvasRef.current || !imageSize.width || !imageSize.height) return; // Ensure canvas and image sizes are available

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');


        const drawPolygon = (polygon, isHovered = false, isSelected = false, matchedTypes = [], detectionType = false) => {
            ctx.beginPath();
            polygon.forEach((point, index) => {
                const x = point.X * imageSize.width;
                const y = point.Y * imageSize.height;
                if (index === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }
            });
            ctx.closePath();

            if (isSelected) {
                ctx.fillStyle = 'rgba(255, 165, 0, 0.3)'; // Orange fill for selected polygon
                ctx.fill();
            }

            // Define colors for different match types
            let matchColor = 'lightgray'; // Default color for non-matched polygons
            if (matchedTypes.includes('name')) {
                matchColor = 'blue'; // Blue for name matches
            } else if (matchedTypes.includes('postfachnummer')) {
                matchColor = 'green'; // Green for postfachnummer matches
            }

            if(detectionType === 'LINE' && ! matchedTypes.includes('name')){
                matchColor = 'lightgray';
            }

            // Determine line thickness and stroke style
            ctx.lineWidth = isHovered || matchedTypes.length > 0 ? 3 : 0.5; // Thicker line if hovered or matched
            ctx.strokeStyle = matchedTypes.length > 0 ? matchColor : isHovered ? 'red' : 'lightgray'; // Set stroke color based on match type
            ctx.stroke();
        };


        const drawPolygons = () => {
            if (ocrResponse && imageSize.width > 0 && imageSize.height > 0) {
                ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

                ocrResponse.TextDetections.forEach((detection) => {
                    const isHovered = detection.Type === 'WORD'
                        ? hoveredWord === detection
                        : hoveredLine === detection;
                    const isSelected = selectedPolygon === detection;
                    const matchedTypes = detection.matched || []; // Get matched types or default to an empty array
                    const detectionType = detection.Type;
                    drawPolygon(detection.Geometry.Polygon, isHovered, isSelected, matchedTypes, detectionType);
                });
            }
        };

        drawPolygons();
    }, [ocrResponse, imageSize, hoveredWord, hoveredLine, selectedPolygon]);

    const handleImageLoad = () => {
        const image = imageRef.current;
        if (image) {
            setImageSize({
                width: image.naturalWidth,
                height: image.naturalHeight,
            });
        }
    };

    const handleMouseMove = (event) => {
        if (!canvasRef.current) return; // Ensure canvas exists before trying to access context

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d'); // Get the 2D context here
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left) / rect.width * imageSize.width;
        const y = (event.clientY - rect.top) / rect.height * imageSize.height;

        let foundWord = null;
        let foundLine = null;

        // First, check for WORD type polygons
        ocrResponse.TextDetections.forEach((detection) => {
            if (detection.Type === 'WORD') {
                const polygon = detection.Geometry.Polygon;
                if (ctx.isPointInPath(createPolygonPath(polygon), x, y)) {
                    foundWord = detection;
                }
            }
        });

        // Then, check for LINE type polygons if no WORD is found
        ocrResponse.TextDetections.forEach((detection) => {
            if (detection.Type === 'LINE') {
                const polygon = detection.Geometry.Polygon;
                if (ctx.isPointInPath(createPolygonPath(polygon), x, y)) {
                    foundLine = detection;
                }
            }
        });

        setHoveredWord(foundWord);
        setHoveredLine(foundLine);
    };

    const createPolygonPath = (polygon) => {
        const path = new Path2D();
        polygon.forEach((point, index) => {
            const x = point.X * imageSize.width;
            const y = point.Y * imageSize.height;
            if (index === 0) {
                path.moveTo(x, y);
            } else {
                path.lineTo(x, y);
            }
        });
        path.closePath();
        return path;
    };

    const handleContextMenu = (event, detection) => {
        event.preventDefault();
        const x = event.clientX;
        const y = event.clientY;
        setContextMenu({
            x,
            y,
            detection,
        });
        setSelectedPolygon(detection); // Highlight the selected polygon
        setValue(detection?.DetectedText)
    };

    const handleClick = (event) => {
        if (hoveredLine && event.button === 0) {
            handleContextMenu(event, hoveredLine); // Left click for LINE
        } else if (hoveredWord && event.button === 2) {
            handleContextMenu(event, hoveredWord); // Right click for WORD
        }
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
        setSelectedPolygon(null); // Remove polygon highlighting
    };

    const [value, setValue] = useState(null);

    return (
        <Box sx={{ position: 'relative' }}>
            {/* The image */}
            <Box
                component="img"
                src={imageUrl}
                alt="Detected Text"
                ref={imageRef}
                onLoad={handleImageLoad} // Call when the image is loaded to get its size
                sx={{ width: '100%' }}
                onMouseMove={handleMouseMove}
                onClick={handleClick} // Handle left click for LINE
                onContextMenu={handleClick} // Handle right click for WORD
            />

            {/* The canvas */}
            <canvas
                ref={canvasRef}
                width={imageSize.width} // The canvas width matches the image width
                height={imageSize.height} // The canvas height matches the image height
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    pointerEvents: 'none', // Allow clicks to go through
                    width: '100%',
                    height: 'auto',
                }}
            />

            {/* Context Menu */}
            <Menu
                open={!!contextMenu}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu ? { top: contextMenu.y, left: contextMenu.x } : undefined
                }
            >
                <MenuItem disableRipple >
                    <TextField
                        sx={{ minWidth: '500px' }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </MenuItem>
                {contextMenu?.detection?.matched?.length > 0 && (
                    <MenuItem>
                        {`Matched: ${contextMenu?.detection?.matched.join(', ')}`}
                    </MenuItem>
                )}
                <MenuItem>
                    <Button
                        onClick={() => {
                            setCustomerData('absender', value)
                            updateDetection(contextMenu?.detection, value)
                            setSelectedPolygon(contextMenu?.detection); // Color the polygon
                            setFeedback({ ...feedback, [contextMenu?.detection?.Id]: value });
                            handleCloseContextMenu(); // Close the context menu
                        }}
                    >
                        Absender speichern
                    </Button>
                </MenuItem>
                {/* Add more menu items for other detection types 
                <MenuItem>
                    <Button
                        onClick={() => {
                            setSelectedPolygon(contextMenu?.detection); // Color the polygon
                            handleCloseContextMenu(); // Close the context menu
                        }}
                    >
                        Sendungsnummer
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => {
                            setSelectedPolygon(contextMenu?.detection); // Color the polygon
                            handleCloseContextMenu(); // Close the context menu
                        }}
                    >
                        Kundennummer
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => {
                            setSelectedPolygon(contextMenu?.detection); // Color the polygon
                            handleCloseContextMenu(); // Close the context menu
                        }}
                    >
                        Vorname
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => {
                            setSelectedPolygon(contextMenu?.detection); // Color the polygon
                            handleCloseContextMenu(); // Close the context menu
                        }}
                    >
                        Nachname
                    </Button>
                </MenuItem>
                */}
            </Menu>
        </Box>
    );
};

export default ImageWithPolygons;
