import React, { useEffect, useState, useRef, useCallback } from "react";
import useSound from 'use-sound';

import {
    Routes,
    Route,
    Outlet,
    useNavigate,
} from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    Button,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Chip,
} from "@mui/material";
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';

import errorSound from '../sounds/error.mp3';
import successSound from '../sounds/success.mp3';
import openSound from '../sounds/open.mp3';
import { East, ViewTimeline } from "@mui/icons-material";
import { ShipmentHistoryButton } from "./Checkout";
import { WarehouseModalIconButton, WarehouseSlotHistoryView } from "./WarehouseSlotHistory";
import LagerplatzDetails from "./LagerplatzDetails";

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const CheckinLayout = () => {
    const [open, setOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['checkinDeviceId']);
    const [temp, setTemp] = useState(cookies.checkinDeviceId);

    const save = () => {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 366 * 1000)
        setCookie('checkinDeviceId', temp, { path: '/', expires })
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, pb: 1 }}>
                <Typography variant="h6" gutterBottom>Einlagerung / Umlagerung</Typography>
                <Button onClick={() => setOpen(!open)} size="small" variant="outlined">DID: {cookies.checkinDeviceId}</Button>
            </Box>
            {open && <Alert severity="error" sx={{ p: 2, mb: 2, maxWidth: '500px' }}><Typography>Device ID (10-99) Festlegen</Typography><TextField value={temp} onChange={(evt) => { setTemp(evt.target.value) }} /><Button disabled={temp.length !== 2} onClick={save}>Speichern</Button></Alert>}
            <Outlet />
        </>
    )
}

const Storage = ({ posConfig }) => {
    return (
        <>
            <Routes>
                <Route path="/" element={<CheckinLayout />}>
                    <Route index element={<StoreShipment posConfig={posConfig} />} />
                    <Route path=":customerId" element={<EditShipment posConfig={posConfig} />} />
                </Route>
            </Routes>
        </>
    )
}

export default Storage;


const StoreShipment = ({ posConfig }) => {

    const [cookies, setCookie] = useCookies(['checkinDeviceId']);
    const [temp, setTemp] = useState("");

    const save = () => {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 366 * 1000)
        setCookie('checkinDeviceId', temp, { path: '/', expires })
    };

    if (!cookies.checkinDeviceId) {
        return <Paper sx={{ p: 2 }}><Typography>Device ID (10-99) Festlegen</Typography><TextField onChange={(evt) => { setTemp(evt.target.value) }} /><Button disabled={temp.length !== 2} onClick={save}>Speichern</Button></Paper>
    }



    return (
        <StoreShipmentApp posConfig={posConfig} />
    )
}


const EditShipment = () => { };




const StoreShipmentApp = ({ posConfig }) => {


    const [cookies] = useCookies(['secret', 'station', 'staff', 'checkinDeviceId']);
    const sidInputRef = useRef(null);

    const [comment, setComment] = useState({})
    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const relocateShipment = (slot, mpsid) => {
        sendMessage(JSON.stringify({ task: 'relocateShipment', station: posConfig.connection.station, staff: cookies.staff, slot, mpsid, did: cookies.checkinDeviceId }))
    };


    const getSlotDetails = (slot) => {
        setSlotDetails(null);
        sendMessage(JSON.stringify({ task: 'getSlotDetails', station: posConfig.connection.station, staff: cookies.staff, slot, did: cookies.checkinDeviceId }))
    };

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respRelocateShipment' && msg.status === "succeeded") {
            setSlotDetails(msg);
            alertShipmentRelocated();
            setValue("");
            sidInputRef.current.focus();
            setComment({ msg: "Eingebucht: " + msg.mpsid + " in " + msg.name, type: 'success' })
        }

        if (msg.task === 'respRelocateShipment' && msg.status !== "succeeded") {
            alertError();
            setValue("");
            sidInputRef.current.focus();
            setComment({ msg: "FEHLER - Nicht eingebucht: " + msg.mpsid, type: 'error' })
        };

        if (msg.task === 'respGetSlotDetails') {
            setSlotDetails(msg);
        }

    };


    const checkX = (str) => {
        let hadX = false;
        if (str[0] === "x" && str[str.length - 1] === "x") {
            hadX = true;
            str = str.slice(1, -1);
        }
        return {
            input: str,
            hadX: hadX
        };
    }

    const [slot, setSlot] = useState(null);
    const [slotDetails, setSlotDetails] = useState(null);

    const [value, setValue] = useState("");

    const handleScanEvent = (val) => {
        if (checkX(val)) {

            if (val.length === 7) {
                const tmp = val.replaceAll('x', '');
                setSlot(tmp);
                getSlotDetails(tmp);
                sidInputRef.current.focus();
                alertSlotOpen();
                setValue("");
                setComment({ msg: "Lager offen", type: 'success' })
            } else {
                if (slot === null) {
                    alertError();
                    setComment({ msg: "FEHLER - Kein Lager offen", type: 'error' })
                } else {
                    if (val.length > 8) {
                        relocateShipment(slot, val.replaceAll('x', ''));
                    } else {
                        alertError();
                        setComment({ msg: "FEHLER - MPSID zu kurz", type: 'error' })
                        setValue("");
                    }
                }
            }


        } else {
            alertError();
            setValue("");
            setComment({ msg: "FEHLER - Kein Barcode", type: 'error' })
        }
    };

    useEffect(() => {
        if (value === "") {
            sidInputRef.current.focus();
        }
    }, [value])

    const [playError, { stop1 }] = useSound(errorSound);
    const [playSuccess, { stop2 }] = useSound(successSound);
    const [playOpen, { stop3 }] = useSound(openSound);

    const alertError = () => {
        playError();
    };

    const alertSlotOpen = () => {
        playOpen();
    };

    const alertShipmentRelocated = () => {
        playSuccess();
    };

    const staffMap = slotDetails?.staffInfo?.reduce((p,c)=>{
        p[c.id] = c.vorname + ' ' + c.nachname;
        return p;
    },{});

    const warehouseMap = slotDetails?.warehouseInfo?.reduce((p,c)=>{
        p[c.id] = c.text;
        return p;
    },{});

    return (
        <Box>
            <Paper sx={{ mb: 2, p: 4, width: '100%' }} >

                <TextField
                    inputRef={sidInputRef}
                    onMouseEnter={() => { sidInputRef.current.focus(); }}
                    fullWidth
                    onKeyPress={(evt) => {
                        if (evt.charCode === 13) {
                            handleScanEvent(evt.target.value)
                        }
                    }}
                    autoComplete="off"
                    size="small"
                    value={value}
                    onChange={(evt) => setValue(evt.target.value)}
                />
                {!!comment?.msg && <Alert sx={{ mt: 2 }} severity={comment.type}>{comment.msg}</Alert>}
                {!!slot && slotDetails === null && <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />}
                {slotDetails !== null && <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Sendungen in {slotDetails.name}</Typography>
                    <WarehouseModalIconButton slot={slot} />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>MPSID</TableCell>
                                <TableCell>Eingangsdatum</TableCell>
                                <TableCell>KDNR</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Klasse</TableCell>
                                <TableCell>Sendungsnummer</TableCell>
                                <TableCell>Absender</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slotDetails.items.map((e, i) => {
                                return (<TableRow key={i}>
                                    <TableCell>{e.id} <ShipmentHistoryButton id={e.id}  /></TableCell>
                                    <TableCell>{new Date(e.creationdate).toLocaleString('de-DE')}</TableCell>
                                    <TableCell>{e.kdnr}</TableCell>
                                    <TableCell>{e.firstname}</TableCell>
                                    <TableCell>{e.lastname}</TableCell>
                                    <TableCell>{e.klasse}</TableCell>
                                    <TableCell>{e.sendungsnummer}</TableCell>
                                    <TableCell>{e.absender}</TableCell>
                                </TableRow>);
                            })}
                        </TableBody>
                    </Table>
                </>}
                {slotDetails?.history.length &&
                    <WarehouseSlotHistoryView slot={slot} staffMap={staffMap} warehouseMap={warehouseMap} history={slotDetails.history} preview={true} />
                }
            </Paper>
        </Box>
    );
}
