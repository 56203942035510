import { Box, MenuItem, MenuList, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const Explorer = () => {
    return (
        <Routes>
            <Route path="/" element={<ExplorerLayout />}>
                <Route index element={<ObjectsList />} />
                <Route path=":objectKey" element={<ObjectBrowser />} />
                <Route path=":objectKey/:itemId" element={<ItemBrowser />} />
            </Route>
        </Routes>
    );
};

export default Explorer;

const ExplorerLayout = () => {

    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h6">Explorer</Typography>
            <Box sx={{ display: 'flex' }}>
                <Box flex={1}>
                    <MenuList>
                        {objects.map((e, i) => {
                            return (
                                <MenuItem key={i} onClick={() => navigate(e.key)}>
                                    {e.label}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </Box>
                <Box flex={5}>
                    <Outlet />
                </Box>
            </Box>
        </>
    )
}

const objects = [{ label: 'Kunden', key: 'customers' }, { label: 'Sendungen', key: 'shipments' }, { label: 'Mitarbeiter', key: 'staff' }, { label: 'Lagerplätze', key: 'warehouse' }]

const ObjectsList = () => {

    return (
        <Box>

        </Box>
    );
};

const rows = [
    {
        id: 1,
        name: 'Obj 1'
    },
    {
        id: 2,
        name: 'Obj 1'
    },
    {
        id: 3,
        name: 'Obj 1'
    },
];

const columns = [
    {
        field: 'id',
        type: 'number',
        headerName: 'Id',
        description: 'ID',
        sortable: true
    },
    {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        sortable: false
    }
];

const ObjectBrowser = () => {
    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [data, setData] = useState({ rows: [], totalCount: 0 });
    const [params, setParams] = useState(null);
    const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => true,
        queryParams: {
            station: cookies.station,
            secret: cookies.secret,
            staff: cookies.staff
        },
    });

    const requestIdRef = useRef(0);

    const customDataSource = useCallback(
        async (params) => {
            console.log({ params });
            const requestId = ++requestIdRef.current;
            setParams({ ...params, requestId });

            return new Promise((resolve) => {
                const handleMessage = (event) => {
                    const responseData = JSON.parse(event.data);
                    if (responseData.requestId === requestId) {
                        setData({
                            rows: responseData.rows,
                            totalCount: responseData.totalCount,
                        });
                        resolve({
                            rows: responseData.rows,
                            rowCount: responseData.totalCount,
                        });
                    }
                };

                if (lastMessage) {
                    handleMessage(lastMessage);
                }
            });
        },
        [lastMessage]
    );

    useEffect(() => {
        if (params) {
            sendMessage(JSON.stringify({ task: 'getExplorerData', station: cookies.station, staff: cookies.staff, params }));
        }
    }, [params, sendMessage, cookies]);

    useEffect(() => {
        if (lastMessage) {
            const responseData = JSON.parse(lastMessage.data);
            if (responseData.requestId === requestIdRef.current) {
                setData({
                    rows: responseData.rows,
                    totalCount: responseData.totalCount,
                });
            }
        }
    }, [lastMessage]);

    return (
        <Box>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    unstable_dataSource={customDataSource}
                    columns={columns}
                    pagination
                />
            </Box>
        </Box>
    );
};


const ItemBrowser = () => {
    const { objectKey, itemId } = useParams();
    return (
        <Box>
            Object {objectKey} Item {itemId}
        </Box>
    );
};