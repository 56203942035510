import { Alert, Box, Button, FormControlLabel, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { SearchField } from './Checkout';
import { InfoOutlined } from '@mui/icons-material';
import useWebSocket, { resetGlobalState } from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import SeparateEscrowAccountDashboard, { getTypeColor, getTypeLabel } from './SeparateEscrowAccountDashboard';
const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';
const BAGATELLIMIT = 3000;

const PaymentJournal = ({ posConfig }) => {

    const [data, setData] = useState();

    const now = new Date();
    const [dateString, setDateString] = useState([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('#'));

    const [cookies] = useCookies(['secret', 'station', 'staff'])

    useEffect(() => {
        getJournal();
    }, [dateString]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetJournal') {

            const { records } = msg;
            setData({ records });

        };

    };

    const getJournal = () => {
        sendMessage(JSON.stringify({ task: 'getJournal', station: posConfig.connection.station, staff: cookies.staff, dateString }))
    }


    if (!data?.records) return;





    return (
        <>
            <Paper sx={{ p: 4 }}>
                <Typography variant='h5' gutterBottom>Zahlungs-Journal</Typography>
                <Select value="open">
                    <MenuItem value="open">Heute (nicht abgeschloßen)</MenuItem>
                </Select>
                <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Buchungen</Typography>
                    <ReportTable data={data} />
                    {/*<pre>{JSON.stringify({ reportByStaff }, null, 2)}</pre>*/}

                </Box>
            </Paper>
        </>
    );
};
//<JournalListSimple records={data.records.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))} />
export default PaymentJournal;


const formatCurrency = (amount) => {
    return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount / 100)
};


function transformData(records, group) {
    const staffData = {};

    records.forEach(item => {
        const staff = item[group];

        // Initialisiere den Eintrag für den Mitarbeiter, falls noch nicht vorhanden
        if (!staffData[staff]) {
            staffData[staff] = { [item.method]: {} };
        }

        if (!staffData[staff][item.method]) {
            staffData[staff][item.method] = {};
        }

        if (!staffData[staff][item.method][item.posMethod || "PRE"]) {
            staffData[staff][item.method][item.posMethod || "PRE"] = {};
        }

        Object.keys(item.count).forEach(kategorie => {
            const anzahl = item.count[kategorie];
            const summe = item.sum[kategorie] || 0;

            // Initialisiere die Kategorie für den Mitarbeiter, falls noch nicht vorhanden
            if (!staffData[staff][item.method][item.posMethod || "PRE"][kategorie]) {
                staffData[staff][item.method][item.posMethod || "PRE"][kategorie] = { count: 0, sum: 0 };
            }

            // Aggregiere die Anzahl und Summe
            staffData[staff][item.method][item.posMethod || "PRE"][kategorie].count += anzahl;
            staffData[staff][item.method][item.posMethod || "PRE"][kategorie].sum += summe;
        });
    });

    return staffData;
}



function ReportTable({ data }) {

    const [groupBy, setGroupBy] = useState('staff');
    const records = data.records;


    // Funktion, um die Daten für die Tabelle vorzubereiten
    const prepareTableData = (data) => {
        const tableData = [];

        Object.keys(data).forEach(staffId => {
            const staffData = { staffId, 'Total': { cash: { sum: 0, count: 0 }, card: { sum: 0, count: 0 }, PRE: { sum: 0, count: 0 } } };
            const paymentTypes = data[staffId];

            // Iteriere durch Zahlungstypen und -methoden, ignoriere aber PRE PRE
            Object.keys(paymentTypes).forEach(type => {
                Object.keys(paymentTypes[type]).forEach(method => {
                    //if (!(type === 'PRE' && method === 'PRE')) { // Ignoriere PRE PRE
                        const categories = paymentTypes[type][method];
                        console.log({ type, method })
                        Object.keys(categories).forEach(category => {
                            if (!staffData[category]) {
                                staffData[category] = {};
                            }
                            if (!staffData[category][method]) {
                                staffData[category][method] = { count: 0, sum: 0 };
                            }
                            staffData[category][method].count += categories[category].count;
                            staffData[category][method].sum += categories[category].sum;
                            staffData['Total'][method].sum += categories[category].sum;
                            staffData['Total'][method].count += categories[category].count;
                        });
                    //}
                });
            });

            tableData.push(staffData);
        });

        return tableData;
    };

    const reportByStaff = transformData(records, groupBy);

    const tableData = prepareTableData(reportByStaff);

    console.log({ tableData });

    // Extrahiere die einzigartigen Kategorien aus den Daten
    const categories = [...new Set(tableData.flatMap(item => Object.keys(item).filter(key => key !== 'staffId')))];

    return (
        <>
            <Select value={groupBy} onChange={(evt) => { setGroupBy(evt.target.value) }}>
                <MenuItem value="station">Umsatz nach Kasse gruppiert</MenuItem>
                <MenuItem value="staff">Umsatz nach Mitarbeiter gruppiert</MenuItem>
            </Select>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Staff ID</TableCell>
                            <TableCell>Zahlungsart</TableCell>
                            {categories.map(category => (
                                <TableCell key={category}>{category}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell rowSpan={3}>{row.staffId}</TableCell>
                                    <TableCell >Bargeld</TableCell>
                                    {categories.map(category => {
                                        const e = 'cash';
                                        return (
                                            <TableCell key={category}>
                                                <Typography>
                                                    {row[category]?.[e] ? <span>{formatCurrency(row[category][e].sum)}<br /><small>{row[category][e].count}x</small></span> : '-'}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Kartenzahlung</TableCell>
                                    {categories.map(category => {
                                        const e = 'card';
                                        return (
                                            <TableCell key={category}>
                                                <Typography>
                                                    {row[category]?.[e] ? <span>{formatCurrency(row[category][e].sum)}<br /><small>{row[category][e].count}x</small></span> : '-'}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Guthaben</TableCell>
                                    {categories.map(category => {
                                        const e = 'PRE';
                                        return (
                                            <TableCell key={category}>
                                                <Typography>
                                                    {row[category]?.[e] ? <span>{formatCurrency(row[category][e].sum)}<br /><small>{row[category][e].count}x</small></span> : '-'}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}



