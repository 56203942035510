import React, { useEffect, useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';

import {
    Routes,
    Route,
    Outlet,
} from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
    Autocomplete,
    CircularProgress,
    Drawer,
    Fab,
    Collapse,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import upsLogo from '../speditionen/ups.png';
import dhlLogo from '../speditionen/dhl.png';
import dpdLogo from '../speditionen/dpd.png';
import hermesLogo from '../speditionen/hermes.png';
import amazonLogo from '../speditionen/amazon.png';
import dhlexpressLogo from '../speditionen/dhlexpress.png';
import fedexLogo from '../speditionen/fedex.png';
import glsLogo from '../speditionen/gls.png';
import postLogo from '../speditionen/post.png';
import transoflexLogo from '../speditionen/transoflex.png';

import axios from "axios";
import uuid from "react-uuid";
import { Cancel, Check, History, ZoomIn } from "@mui/icons-material";

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';
const restUrl = 'https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod';

const CheckinLayout = () => {
    const [open, setOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['checkinDeviceId']);
    const [temp, setTemp] = useState(cookies.checkinDeviceId);

    const save = () => {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 366 * 1000)
        setCookie('checkinDeviceId', temp, { path: '/', expires })
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, pb: 1 }}>
                <Typography variant="h6" gutterBottom>Sendungserfassung</Typography>
                <Button onClick={() => setOpen(!open)} size="small" variant="outlined">DID: {cookies.checkinDeviceId}</Button>
            </Box>
            {open && <Alert severity="error" sx={{ p: 2, mb: 2, maxWidth: '500px' }}><Typography>Device ID (10-99) Festlegen</Typography><TextField value={temp} onChange={(evt) => { setTemp(evt.target.value) }} /><Button disabled={temp.length !== 2} onClick={save}>Speichern</Button></Alert>}
            <Outlet />
        </>
    )
}

export {CheckinLayout};

const CheckinLayout2 = () => {
    const [open, setOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['checkinDeviceId']);
    const [temp, setTemp] = useState(cookies.checkinDeviceId);

    const save = () => {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 366 * 1000)
        setCookie('checkinDeviceId', temp, { path: '/', expires })
    };

    if (!cookies.checkinDeviceId) {
        return <Paper sx={{ p: 2 }}><Typography>Device ID (10-99) Festlegen</Typography><TextField onChange={(evt) => { setTemp(evt.target.value) }} /><Button disabled={temp.length !== 2} onClick={save}>Speichern</Button></Paper>
    }

    return (
        <>
            <Outlet />
        </>
    )
}

export {CheckinLayout2};

const Checkin = ({ posConfig }) => {
    return (
        <>
            <Routes>
                <Route path="/" element={<CheckinLayout />}>
                    <Route index element={<CheckinShipment posConfig={posConfig} />} />
                    <Route path=":customerId" element={<EditShipment posConfig={posConfig} />} />
                </Route>
            </Routes>
        </>
    )
}

export default Checkin


const CheckinShipment = ({ posConfig }) => {

    const [cookies, setCookie] = useCookies(['checkinDeviceId']);
    const [temp, setTemp] = useState("");

    const save = () => {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 366 * 1000)
        setCookie('checkinDeviceId', temp, { path: '/', expires })
    };

    if (!cookies.checkinDeviceId) {
        return <Paper sx={{ p: 2 }}><Typography>Device ID (10-99) Festlegen</Typography><TextField onChange={(evt) => { setTemp(evt.target.value) }} /><Button disabled={temp.length !== 2} onClick={save}>Speichern</Button></Paper>
    }



    return (
        <RegisterShipmentApp posConfig={posConfig} />
    )
}


const EditShipment = () => { };




const RegisterShipmentApp = ({ posConfig }) => {

    const [label, setLabel] = useState();

    const [mpsid, setMpsid] = useState();
    const [kdnr, setKdnr] = useState();

    const [spedition, setSpedition] = useState(null);
    const [predictedSpedition, setPredictedSpedition] = useState(null);
    const [sid, setSid] = useState("");
    const [klasse, setKlasse] = useState(null);
    const [absender, setAbsender] = useState();
    const [photoFileKey, setPhotoFileKey] = useState();
    const [incomplete, setIncomplete] = useState([]);

    const [cookies] = useCookies(['secret', 'station', 'staff', 'checkinDeviceId']);
    const kdnrInputRef = useRef(null);
    const senderInputRef = useRef(null);
    const sidInputRef = useRef(null);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );


    const [isDuplicate, setIsDuplicate] = useState(false);
    const [allowDuplicate, setAllowDuplicate] = useState(false);
    const [duplicates, setDuplicates] = useState([]);

    const checkinShipment = useCallback(() => {
        sendMessage(JSON.stringify({ task: 'checkinShipment', station: posConfig.connection.station, staff: cookies.staff, sid, kdnr, klasse, spedition, absender, photoFileKey, incomplete, did: cookies.checkinDeviceId, allowDuplicate }))
    }, [sendMessage, posConfig.connection.station, cookies, sid, kdnr, kdnr, klasse, spedition, absender, incomplete, allowDuplicate]);



    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respCheckinShipment' && msg.status === "duplicate") {
            setIsDuplicate(true);
            setDuplicates(msg.duplicates)
        }

        if (msg.task === 'respCheckinShipment' && msg.status === "succeeded") {
            setMpsid(msg.mpsid);
            setLabel(msg.label);
        }

        if (msg.task === 'respGetShipmentLabel') {
            setLabel(msg.label)
        }

    };

    useEffect(() => {
        if (mpsid) {
            handlePrint();
            resetHandler();
        }
    }, [mpsid])


    function predictDeliveryCompany(shipmentNumber) {

        const rules = [
            { name: 'Hermes', rule: /^(3S[A-Z0-9]{11}|[0-9]{14}|H[0-9]{19})$/ },
            { name: 'Trans-o-Flex', rule: /^[0-9]{24}$/ },
            { name: 'FedEx', rule: /^([0-9]{28}|[0-9]{34})$/ },
            { name: 'Post', rule: /^0034[0-9]{16}$/ },
            { name: 'DPD', rule: /^[0-9]{15}$/ },
            { name: 'Post', rule: /^([A-F0-9]{20}|[A-Z0-9]{11}[A-Z]{2})$/ },
            { name: 'GLS', rule: /^([0-9]{12}|[YZ][A-Z0-9]{7})$/ },
            { name: 'UPS', rule: /^1Z[A-Z0-9]{16}$/ },
            { name: 'Amazon', rule: /^DE[0-9]{10}$/ },
            { name: 'DHL Express', rule: /^[0-9]{10}$/ },
            { name: 'DHL', rule: /^([0-9]{12}|JVGL.+|0034.+|JJ.+)$/ }
        ];

        let results = [];
        for (let i = 0; i < rules.length; i++) {
            const company = rules[i].name;
            const regex = rules[i].rule;
            if (regex.test(shipmentNumber)) {
                results.push(company);
            }
        };

        return results;
    }

    const handleShipmentId = (shipmentId) => {
        setPredictedSpedition(predictDeliveryCompany(shipmentId));
        setSid(shipmentId);
        setLabel();
    };


    const [img, setImg] = useState(null);


    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImg(imageSrc);
        },
        [webcamRef]
    );

    const [kunde, setKunde] = useState();

    const resetHandler = () => {
        setKdnr();
        setKunde();
        setImg(null);
        setSpedition(spedition);
        setSid("");
        setKlasse(null);
        setPredictedSpedition();
        setIncomplete([]);
        setAreas({});
        setAbsender();
    };

    useEffect(() => {
        if (sid) {
            capture();
        }
    }, [sid])


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    useEffect(() => {
        if (spedition === 'DPD' && sid && sid?.match(/^%0079713/) !== null) {
            const newSid = sid.replace(/^%0079713/, '');
            setSid(newSid);
            setPredictedSpedition(predictDeliveryCompany(newSid));
        }

        if (spedition === 'GLS' && sid && sid?.length > 30) {
            const newSid = /^.{9}\s.{23}([YZ][A-Z0-9a-z]{7})/g.exec(sid)?.[1];
            if (newSid?.length === 8) {
                setSid(newSid);
                setPredictedSpedition(predictDeliveryCompany(newSid));
            }
        }
    }, [spedition, sid])



    const validate = () => {
        let errors = [];
        if (!absender) {
            errors.push('Absender fehlt');
        }

        if (!klasse) {
            errors.push('Paketklasse fehlt');
        }

        if (!spedition) {
            errors.push('Spedition fehlt');
        }

        if (!kdnr) {
            errors.push('Empfänger fehlt');
        }


        if (!sid) {
            errors.push('Sendungsnummer fehlt');
        }



        return errors;
    };

    const [open, setOpen] = useState(false);



    const [areas, setAreas] = useState({});
    const activateUIArea = (area) => {
        if (!areas?.[area]) {
            setAreas({ ...areas, [area]: true });
        }
    };

    useEffect(() => {
        console.log('areas.kdnr')
        if (areas.kdnr) {
            kdnrInputRef?.current?.focus();
            console.log('areas.kdnr true')
        }
    }, [areas.kdnr])

    useEffect(() => {
        if (areas.absender || spedition) {
            senderInputRef?.current?.focus();
        }
    }, [areas.absender, spedition])

    return (
        <Box>

            <Paper sx={{ mb: 2, p: 4, display: 'flex', gap: 4, width: '100%' }} >
                <Box sx={{ flex: 1 }}>

                    <Typography variant="subtitle2" gutterBottom>Sendungsnummer</Typography>
                    <SidScannerField sidInputRef={sidInputRef} onSelect={(evt) => { handleShipmentId(evt); activateUIArea('kdnr'); }} sid={sid} />
                    <Collapse in={!!areas.kdnr}>

                        <Typography variant="subtitle2">Empfänger</Typography>
                        {!kdnr &&
                            <SearchField kdnrInputRef={kdnrInputRef} PaperComponent={Box} paperSx={{ pt: 2, pb: 2 }} onSelect={(obj) => { setKdnr(obj.id); setKunde(obj.name); activateUIArea('spedition'); }} posConfig={posConfig} noAutofocus={true} />
                        }
                        {!!kdnr && !kunde && <>
                            Kunde nicht gefunden
                        </>}
                        {!!kunde && <>
                            <Box sx={{ display: 'flex' }}>
                                <TextField size="small" fullWidth value={kdnr + " " + kunde} disabled />
                                <Button color="error" variant="outlined" onClick={() => { setKdnr(); setKunde(); }}>X</Button>
                            </Box>
                            <IncompleteData onChange={setIncomplete} incomplete={incomplete} />
                        </>}
                        <Collapse in={!!areas.spedition}>

                            <Typography variant="subtitle2" gutterBottom>Spedition</Typography>
                            <SpeditionSelector value={spedition} onChange={(val) => { setSpedition(val); activateUIArea('absender'); }} predictedSpedition={predictedSpedition} />
                            <Collapse in={!!(spedition || areas.absender)}>
                                <Typography variant="subtitle2" gutterBottom>Absender</Typography>
                                <SenderInformation senderInputRef={senderInputRef} sid={sid} spedition={spedition} onChange={(val) => { if (val.length) { setAbsender(val); activateUIArea('sendungsklasse'); } }} posConfig={posConfig} />
                                <Collapse in={!!areas.sendungsklasse}>
                                    <Typography variant="subtitle2" gutterBottom>Sendungsklasse</Typography>
                                    <ShipmentClass value={klasse} onChange={setKlasse} />
                                    {isDuplicate && <Alert severity="error" sx={{ mb: 2 }}>
                                        <Typography>Achtung: Die Sendungsnummer existiert bereits im System!</Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>MPSID</TableCell>
                                                    <TableCell>Kdnr</TableCell>
                                                    <TableCell>Klasse</TableCell>
                                                    <TableCell>Spedition</TableCell>
                                                    <TableCell>Absender</TableCell>
                                                    <TableCell>Erstellt</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {duplicates.map((e, i) => {
                                                    return (
                                                        <TableRow key={i} sx={{ '> td': { pr: 1 } }}>
                                                            <TableCell>{e.id}</TableCell>
                                                            <TableCell>{e.kdnr}</TableCell>
                                                            <TableCell>{e.klasse}</TableCell>
                                                            <TableCell>{e.spedition}</TableCell>
                                                            <TableCell>{e.absender}</TableCell>
                                                            <TableCell>{new Date(e.creationdate).toLocaleString('de-DE')}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <Checkbox checked={allowDuplicate} onClick={() => setAllowDuplicate(!allowDuplicate)} /> Erlaube Duplikat
                                    </Alert>}
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => {
                                            const errors = validate();

                                            if (errors.length) {
                                                alert(JSON.stringify(errors))
                                            } else {
                                                checkinShipment();
                                            }
                                        }}
                                    >Speichern</Button>
                                    <Button onClick={resetHandler}>Reset</Button>
                                </Collapse>
                            </Collapse>
                        </Collapse>
                    </Collapse>
                </Box>
                <Box>
                    <PhotoCapture webcamRef={webcamRef} />
                    {img && <PhotoPreview capture={capture} img={img} onChange={setPhotoFileKey} />}
                    <LabelPreview componentRef={componentRef} label={label} handlePrint={handlePrint} />
                </Box>
            </Paper>
            <Drawer variant="temporary" anchor="right" open={open} onClose={() => { setOpen(false) }}>
                <Box p={4}>
                    <Paper sx={{ p: 2, minWidth: '280px' }}>
                        <Typography>Letzte Labels</Typography>
                        <LastShipments posConfig={posConfig} onClose={() => { setOpen(false) }} />
                    </Paper>
                </Box>
            </Drawer>
            <Fab variant="extended" sx={{ position: 'absolute', right: 20, bottom: 20 }} onClick={() => { setOpen(true) }}>
                <History sx={{ mr: 1 }} />
                Letzte Labels
            </Fab>
        </Box>
    );
}

const LastShipments = ({ onClose }) => {

    const [kdnr, setKdnr] = useState();
    const [shipments, setShipments] = useState([]);
    const [cookies] = useCookies(['secret', 'staff', 'station'])

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: cookies.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const loadData = useCallback(() => {
        console.log(JSON.stringify({ task: 'getLastCheckins', station: cookies.station, staff: cookies.staff, kdnr }))
        sendMessage(JSON.stringify({ task: 'getLastCheckins', station: cookies.station, staff: cookies.staff, kdnr }))
    }, [sendMessage, cookies.station, cookies.staff, kdnr]);




    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respCheckinShipment') {
            loadData();
        }

        if (msg.task === 'respGetLastCheckins' && msg.shipments?.length > 0) {
            setShipments(msg.shipments);
        }

    };

    const handleLabel = (mpsid) => {
        sendMessage(JSON.stringify({ task: 'getShipmentLabel', station: cookies.station, staff: cookies.staff, mpsid }));
        onClose();
    };

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [kdnr])

    const inputRef = useRef();

    return (
        <Box sx={{ pt: 2, pb: 2 }}>
            <SearchField kdnrInputRef={inputRef} PaperComponent={Box} paperSx={{ pt: 1, pb: 2 }} onSelect={(obj) => { setKdnr(obj.id) }} noAutofocus={true} />
            {kdnr && <Button onClick={() => setKdnr()}>Alle Kunden anzeigen</Button>}
            {shipments.map((e, i) => {
                return (
                    <Box key={i}>
                        <Button startIcon={<ZoomIn />} onClick={() => handleLabel(e.id)}></Button>
                        {e.id} ({e.kdnr})
                    </Box>
                );
            })}
        </Box>
    );
};

export {LastShipments}

const PhotoPreview = ({ img, capture, onChange }) => {



    const [cookies, setCookie] = useCookies(['secret']);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const uploadImage = async (img) => {

            return await axios.post(restUrl + '/checkoutapp/checkinimage', {
                image: img,
            }, {
                headers: {
                    authorization: cookies.secret
                }
            });

        }
        const run = async () => {
            const { data } = await uploadImage(img);
            onChange(data?.s3Key || "failure");
            setStatus(data?.s3Key ? true : false);
            setLoading(false);
        };
        if (img) {
            setLoading(true);
            setStatus(null);
            run();
        }
    }, [img])

    return (
        <Box>
            <Box component="img" src={img} width={200} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {status === true &&
                    <Check color="success" />
                }
                {status === false &&
                    <Cancel color="error" />
                }
                {loading && <CircularProgress size={20} />}
                <Button onClick={capture}>Neues Foto</Button>
            </Box>
        </Box>
    );
};

const LabelPreview = ({ label, handlePrint, componentRef }) => {


    if (!label) return;
    const { mpsid, kdnr, comment, firstname, lastname, klasse, firstPickup } = label;

    return (
        <Box sx={{ border: 'solid 1px gray', p: 2 }}>
            <Box ref={componentRef}>
                {mpsid && <Box sx={{ pl: 1 }}>
                    <Barcode value={'x' + mpsid + 'x'} displayValue={false} height={"50mm"} width={2.25} />
                    <Box px={1}>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '16px', fontWeight: 'bold' }}>MPS-ID: <b>{mpsid}</b></Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '24px', fontWeight: 'bold' }}>{kdnr}</Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '32px', fontWeight: 'bold' }}>{(lastname + "").toUpperCase()}</Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '20px', fontWeight: 'bold' }}>{firstname}</Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '20px', fontWeight: 'bold' }}><b>{klasse}</b></Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: '16px', fontWeight: 'bold' }}>{comment}</Typography>
                        {firstPickup && <Typography sx={{ lineHeight: 1.1, fontSize: '20px' }}>NEUKUNDE</Typography>}
                    </Box>
                </Box>}
            </Box>
            {mpsid && <Button onClick={handlePrint} variant="outlined">Label erneut Drucken</Button>}
        </Box>

    );
};

export {LabelPreview}

const SpeditionSelector = ({ value, predictedSpedition, onChange }) => {

    const values = [
        { name: 'UPS', img: { src: upsLogo } },
        { name: 'DHL Express', img: { src: dhlexpressLogo } },
        { name: 'DHL', img: { src: dhlLogo } },
        { name: 'Hermes', img: { src: hermesLogo } },
        { name: 'DPD', img: { src: dpdLogo } },
        { name: 'Amazon', img: { src: amazonLogo } },
        { name: 'Post', img: { src: postLogo } },
        { name: 'FedEx', img: { src: fedexLogo } },
        { name: 'GLS', img: { src: glsLogo } },
        { name: 'Trans-o-flex', img: { src: transoflexLogo } },
        { name: 'Spedition' },
    ];

    return (
        <>
            <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: 2, pb: 2 }}>
                {
                    values.map((e, i) => {

                        const border = e.name === value ? 'solid 8px blue' : 'solid 8px lightgray';

                        return (
                            <Box key={i} onClick={() => onChange(e.name)} sx={{ minWidth: '100px', textAlign: 'center', border, p: 0.5 }}>
                                {e.img && <Box sx={{ height: '30px' }} component="img" src={e.img.src} />}
                                {!e?.img && <Typography variant="h6" sx={{ height: '30px' }}>{e.name}</Typography>}
                            </Box>
                        );
                    })
                }
            </Box>

            {predictedSpedition !== null && !!value && predictedSpedition?.includes(value) === false && <Alert sx={{ mb: 2 }} severity="warning">Sendungsnummer-Format entspricht nicht dem Norm-Format. Speditions-Auswahl überprüfen!</Alert>}
        </>
    );
}


const SidScannerField = ({ onSelect, sid, sidInputRef }) => {

    const [temp, setTemp] = useState(sid);

    useEffect(() => {
        setTemp(sid);
    }, [sid]);

    useEffect(()=>{
        if(!temp){
            sidInputRef?.current?.focus();
        }
    },[temp]);

    useEffect(() => {
        sidInputRef?.current?.focus();
    }, []);

    return (
        <Box sx={{  display: 'flex' }}>

            <TextField
                inputRef={sidInputRef}
                onMouseEnter={() => { sidInputRef.current.focus(); }}
                fullWidth
                onChange={(evt) => setTemp(evt.target.value)}
                onKeyPress={(evt) => {
                    console.log(evt.charCode, temp)
                    if (evt.charCode === 13) {
                        if (temp) {
                            onSelect(temp);
                            console.log({ temp })
                        }
                    }
                }}
                onBlur={() => {
                    if(temp){
                        onSelect(temp);
                    }
                }}
                value={temp}
                disabled={!!sid}
                autoComplete="off"
                size="small"
            />
            {!!sid && <Button variant="outlined" color="error" onClick={() => onSelect()}>X</Button>}

        </Box>
    );
};

export {SidScannerField}


const SenderInformation = ({ posConfig, sid, spedition, onChange, senderInputRef }) => {



    const [cookies] = useCookies(['secret', 'station', 'staff']);

    const { sendMessage } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const sendRequest = useCallback(() => {
        sendMessage(JSON.stringify({ task: 'checkBdvEntry', station: posConfig.connection.station, staff: cookies.staff, sid }))
    }, [sendMessage, posConfig.connection.station, cookies.staff, sid]);


    const cleanAbsender = (sid, sender) => {
        return sender.replace(new RegExp("^" + sid.substr(0, 5) + '[^\\s]+\\s'), "");
    };
    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respCheckBdvEntry') {

            if (msg?.entry?.absender) {
                setBdv({ sender: msg.entry.absender });
                setValue(cleanAbsender(sid, msg.entry.absender));
            } else {
                setBdv();
                setValue("");
            }
            setLoaded(true);
        }

    };



    const [value, setValue] = useState("");
    const [bdv, setBdv] = useState();

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (sid && spedition) {

            if (bdv?.id !== sid) {
                setBdv();
                setValue("");
            }
            if (spedition === 'DHL') {
                setLoaded(false);
                sendRequest();
            }
            if (spedition === 'Amazon') {
                setValue('Amazon');
            }
            if (!sid) {
                setValue("");
                setBdv();
            }
        } else {
            setBdv();
            setValue("");
        }
    }, [sid, spedition])

    useEffect(() => {
        onChange(value);
    }, [onChange, value]);

    return (
        <Box sx={{ pb: 2 }}>
            <TextField
                fullWidth
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                inputRef={senderInputRef}
                size="small"
            />
            {spedition === 'DHL' && <>
                {loaded && bdv &&
                    <Alert severity="success">
                        BDV Eintrag gefunden
                    </Alert>
                }
                {loaded === false &&
                    <Alert severity="info">
                        Prüfe BDV Eintrag ...
                    </Alert>
                }
                {loaded && sid && !bdv &&
                    <Alert severity="error">
                        Kein BDV Eintrag gefunden
                    </Alert>
                }
            </>}
        </Box>
    );
};


const ShipmentClass = ({ value, onChange }) => {

    return (
        <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: 2, pb: 2 }}>
            {
                ["B", "VT", "S", "M", "L", "XL", "XXL", "G1", "G2", "G3", "P1", "P2", "P3"].map((e, i) => {

                    const border = e === value ? 'solid 8px blue' : 'solid 8px lightgray';

                    return (
                        <Box key={i} onClick={() => onChange(e)} sx={{ minWidth: '100px', textAlign: 'center', border, p: 0.5 }}><Typography>{e}</Typography></Box>
                    );
                })
            }
        </Box>
    );
};


const IncompleteData = ({ incomplete, onChange }) => {
    return (<Box sx={{ pb: 2 }}>
        <FormControlLabel control={<Checkbox checked={incomplete.filter(e => e === 'name').length > 0} onChange={(evt) => {
            const temp = [...incomplete.filter(e => e !== 'name')];
            if (evt.target.checked) {
                temp.push('name');
            }
            onChange(temp);
        }} />} label="Name fehlt / unvollständig." />
        <FormControlLabel control={<Checkbox checked={incomplete.filter(e => e === 'kdnr').length > 0} onChange={(evt) => {
            const temp = [...incomplete.filter(e => e !== 'kdnr')];
            if (evt.target.checked) {
                temp.push('kdnr');
            }
            onChange(temp);
        }} />} label="Kundennummer fehlt." />
    </Box>
    );
};



const PhotoCapture = ({ webcamRef }) => {

    const videoConstraints = {
        width: 2880,
        height: 1620,
        facingMode: "user"
    };
    
    const [mode, setMode] = useState("small");

    const modes = {
        small: { width: 400, height: 300, sx: { p: 2 } },
        big: { width: "100%", height: undefined, sx: {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            p: 0,
        }}
    };

    return (
        <Box sx={modes[mode].sx}>
            <Webcam
                audio={false}
                height={modes[mode].height}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                imageSmoothing={false}
                width={modes[mode].width}
                minScreenshotHeight={1080}
                minScreenshotWidth={1920}
                forceScreenshotSourceSize={true}
                videoConstraints={videoConstraints}
                ref={webcamRef}
                onClick={() => setMode(mode === "small" ? "big" : "small")}
            />
            
        </Box>
    );
};




const SearchField = ({ onSelect, PaperComponent, paperSx, noAutofocus, kdnrInputRef }) => {

    const [temp, setTemp] = useState("");


    const [lastRequestId, setLastRequestId] = useState(0)
    const [searchResults, setSearchResults] = useState([])



    const [cookies] = useCookies(['secret', 'station', 'staff'])

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: cookies.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respSearchKdnrByTerm') {
            const { results, requestId } = msg
            if (requestId === lastRequestId) {
                setSearchResults(results)
            }
        }
    }

    const searchKdnrByTerm = (searchTerm, thisRqId) => {
        sendMessage(JSON.stringify({ task: 'searchKdnrByTerm', station: cookies.station, staff: cookies.staff, searchTerm, requestId: thisRqId }))
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (temp === "") {
                setSearchResults([])
            } else {
                const thisRqId = uuid()
                setLastRequestId(thisRqId)
                searchKdnrByTerm(temp, thisRqId)
            }
        }, 250);
        return () => clearTimeout(timeOutId);
    }, [temp])

    const handlerKeyPress = (evt) => {
        if (evt.keyCode === 13) {
            if (!isNaN(temp)) {
                onSelect(temp)
            }
        }
    }

    useEffect(() => {
        if (!noAutofocus) {
            kdnrInputRef.current.focus();
        }
    }, [])

    const PaperComp = PaperComponent || Paper;




    return (
        <PaperComp sx={paperSx || { mb: 2, p: 4 }} onMouseEnter={() => {
            kdnrInputRef.current.focus();
        }}>
            <Autocomplete
                //disablePortal
                autoHighlight
                freeSolo
                id="combo-box-kdnr-searchterm"
                options={searchResults.map(e => ({
                    label: e.ID + ' - ' + e.display_name,
                    id: e.ID,
                    name: e.display_name
                }))}
                sx={{ width: '100%' }}
                value={temp}
                onInputChange={(evt, value, id) => { setTemp(value) }}
                onChange={(a, b) => {
                    if (b?.id) {
                        onSelect(b)
                        setTemp("")
                    }
                }}
                renderInput={(params) => <TextField variant="outlined" inputRef={kdnrInputRef} {...params} size="small" label="Suche nach Kunde" />}
                onKeyDown={handlerKeyPress}
                noOptionsText={"Suche nach Name oder Email"}
            />
        </PaperComp>
    )
}

export { SearchField };