import { Alert, Box, Button, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchField } from './Checkout';
import { InfoOutlined } from '@mui/icons-material';
import useWebSocket, { resetGlobalState } from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import SeparateEscrowAccountDashboard, { getTypeColor, getTypeLabel } from './SeparateEscrowAccountDashboard';
const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';
const BAGATELLIMIT = 3000;

const SeparateEscrowAccount = ({ posConfig }) => {

    const [kdnr, setKdnr] = useState(false);
    const [data, setData] = useState();

    const [newId, setNewId] = useState();

    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [lastRequestId, setLastRequestId] = useState(0)

    const [status, setStatus] = useState('open');

    useEffect(() => {
        if (data) {
            setData();
        };
        setStatus('open');
        if (kdnr) {
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId)
        }
    }, [kdnr]);


    useEffect(() => {
        if (data) {
            setData();
        };
        if (kdnr) {
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId)
        }
    }, [status]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetSeparateEscrowAccount') {

            const { records, requestId } = msg;

            if (requestId === lastRequestId) {
                setData({ records });
            };

        };

        if (msg.task === 'respPutSeparateEscrowAccountEntry') {
            const { id, pdfUrl, notificationSent } = msg;
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId);
            setNewId(pdfUrl || notificationSent);
            if (pdfUrl) {
                window.open(pdfUrl);
            }
        };

    };

    const getSeparateEscrowAccount = (thisRqId) => {
        sendMessage(JSON.stringify({ task: 'getSeparateEscrowAccount', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, kdnr, status }))
    }

    const putSeparateEscrowAccountEntry = ({ thisRqId, variant, status, type, comment, amount, flow }) => {
        sendMessage(JSON.stringify({ task: 'putSeparateEscrowAccountEntry', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, kdnr, status, type, user: cookies.staff, comment, amount, variant, flow }));
    }

    const resetHandler = () => {
        setNewId();
    }

    return (
        <>
            <SearchField posConfig={posConfig} onSelect={setKdnr} />
            {!data && <SeparateEscrowAccountDashboard posConfig={posConfig} setKdnr={setKdnr} />}
            {data && <Paper sx={{ p: 4}}>
                <Typography variant='h5' gutterBottom>Kundenansicht</Typography>
                <Select value={status} onChange={(evt) => setStatus(evt.target.value)}>
                    <MenuItem value="open">Offene Vorgänge</MenuItem>
                    <MenuItem value="closed">Geschloßene Vorgänge</MenuItem>
                </Select>

                <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Buchungen</Typography>
                    <RecordsListSimple records={data.records.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))} />
                </Box>

                <AddExpenditure addHandler={putSeparateEscrowAccountEntry} resetHandler={resetHandler} kdnr={kdnr} newId={newId} records={data.records} />
            </Paper>}

        </>
    );
};

export default SeparateEscrowAccount;


const ExpenditureStats = () => {
    return (
        <Paper sx={{ my: 4, p: 4 }}>
            <Typography>Stats</Typography>
        </Paper>
    );
};

const AddExpenditure = ({ addHandler, resetHandler, kdnr, records, newId }) => {

    const expenditures = records?.filter(e=>e.type==='expenditure') || [];
    const deposits = records?.filter(e=>e.type==='deposit') || [];

    const expedituresSum = expenditures?.reduce((p, c) => {
        p = p + c.amount;
        return p;
    }, 0);

    const depositsSum = deposits?.reduce((p, c) => {
        p = p + c.amount;
        return p;
    }, 0);

    const totalSum = depositsSum - expedituresSum;


    const [open, setOpen] = useState(false);
    const dataInit = {
        variant: 'NONE',
        amount: "",
        comment: ""
    };
    const [data, setData] = useState(dataInit);

    const [error, setError] = useState({});

    const [mode, setMode] = useState();
    const [flow, setFlow] = useState('expendit');

    const reset = () => {
        setData(dataInit);
        setMode();
        setFlow('expendit');
        resetHandler();
    };

    const handleChangeType = (event) => {
        const value = event.target.value;
        if (value === "NONE") {
            setError({
                ...error,
                type: 'Bitte gültigen Wert auswählen'
            })
        } else {
            setError({
                ...error,
                type: undefined
            });
        }
        setData({
            ...data,
            variant: value
        });
    };

    const handleChangeAmount = (event) => {

        const string = event.target.value;
        const amount = validateNumber(string);

        if (amount) {
            setError({
                ...error,
                amount: undefined
            });
        } else {
            setError({
                ...error,
                amount: 'Bitte gültigen Wert eingeben'
            });
        }

        setMode(decideMode(amount));

        setData({
            ...data,
            amount: string
        });
    };

    const handleChangeComment = (event) => {

        if (event.target.value.length > 10) {
            setError({
                ...error,
                comment: undefined
            });
        } else {
            setError({
                ...error,
                comment: 'Bitte gültigen Wert eingeben'
            });
        }
        setData({ ...data, comment: event.target.value })
    };


    const decideMode = (amount) => {

        if (amount <= BAGATELLIMIT) {
            return 1;
        }
        if (amount - totalSum <= BAGATELLIMIT) {
            return 1;
        }
        return 2;
    };

    const validateNumber = (string) => {
        const cleanedString = string.replace(/\s/g, '').replace(/,/g, '.');
        if (/^\d+(\.\d{1,2})?$/.test(cleanedString)) {
            return parseFloat(cleanedString) * 100;
        } else {
            return false;
        }
    }

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const x = (validateNumber(data.amount) > 0 && data.variant !== 'NONE' && data.comment.length > 10)
        console.log({ x, data })
        setIsValid(x);
    }, [data.amount, data.variant, data.comment]);

    const handleSubmit = () => {
        const type = flow === 'expendit' ? 'expenditure' : 'notification';
        const status = 'open';
        addHandler({ thisRqId: uuid(), status, type, variant: data.variant, comment: data.comment, amount: validateNumber(data.amount), flow })
    };

    return (
        <Paper square sx={{ p: 4, mt: 4 }} variant='outlined'>
            {!open && <Button variant='outlined' onClick={() => setOpen(true)}>Neue Auslage</Button>}
            {open &&
                <Box>
                    {!newId &&
                        <Box>
                            <Typography gutterBottom>Neue Auslage ({kdnr})</Typography>
                            <Box sx={{ display: 'flex', gap: 2, pt: 2 }}>
                                <TextField autoComplete="false" label="Betrag" size="small" value={data.amount} onChange={handleChangeAmount} error={!!error.amount} />
                                <TextField autoComplete="false" label="Sendungsdaten/Kommentar" size="small" value={data.comment} onChange={handleChangeComment} error={!!error.comment} />
                                <Select size='small' value={data.variant} onChange={handleChangeType} error={!!error?.type}>
                                    <MenuItem value="NONE">Bitte wählen...</MenuItem>
                                    {
                                        ["Zollgebühren", "Nachnahme", "Porto-Nachzahlung", "Anderes"].map((e, i) => {
                                            return <MenuItem key={i} value={e}>{e}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Box>
                            {mode === 2 &&
                                <Box sx={{ pt: 2 }}>

                                    <Alert severity='warning'>
                                        Der Betrag ist nicht gedeckt und überschreitet den Begatelbetrag von {formatCurrency(BAGATELLIMIT)} ({formatCurrency((totalSum - validateNumber(data.amount)) * -1)})
                                    </Alert>

                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={flow}
                                        onChange={(evt) => { setFlow(evt.target.value) }}
                                        sx={{ gap: 2, '& .MuiRadio-root': { mr: 2, alignSelf: 'flex-start' }, py: 2, px: 2 }}
                                    >
                                        <FormControlLabel value="expendit" control={<Radio />}
                                            sx={{ border: 'solid 1px lightgray', p: 2 }}
                                            label={<>
                                                <Typography variant="h6">Option 1:</Typography>
                                                <Typography>Trotzdem bezahlen</Typography>
                                                <Alert severity='warning' icon={false}>Nur nach Freigabe!</Alert>
                                            </>}
                                        />
                                        <FormControlLabel value="delay" control={<Radio />}
                                            sx={{ border: 'solid 1px lightgray', p: 2 }}
                                            label={<>
                                                <Typography variant="h6">Option 2:</Typography>
                                                <Typography>Zustellung auf nächsten Tag verschieben</Typography>
                                                <Alert severity='info' icon={false}>
                                                    Kunde wird automatisch informiert!
                                                </Alert>
                                            </>}
                                        />
                                        <FormControlLabel value="reject" control={<Radio />}
                                            sx={{ border: 'solid 1px lightgray', p: 2 }}
                                            label={<>
                                                <Typography variant="h6">Option 3:</Typography>
                                                <Typography>Annahme verweigert</Typography>
                                                <Alert severity='info' icon={false}>Kunde wird automatisch informiert!</Alert>
                                            </>}
                                        />
                                    </RadioGroup>



                                </Box>
                            }
                            <Box sx={{ pt: 2 }}>
                                <Button disabled={!isValid} onClick={handleSubmit} variant='contained'>Speichern</Button>
                            </Box>

                        </Box>

                    }
                    {newId && <Box>
                        {newId !== true && <Button onClick={() => window.open(newId)}>Beleg drucken</Button>}
                        {newId === true && <Alert severity='info'>Kunde wurde informiert.</Alert>}
                        <Button onClick={reset}>Schließen</Button>
                    </Box>}
                </Box>
            }
        </Paper>
    );
};


const RecordsListSimple = ({records}) => {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{pl:0}}>Betrag</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Variante</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.length > 0 && records.map((e, i) => {

                        const amount = e.type !== 'deposit' ? e.amount*-1 : e.amount;
                        return (
                            <TableRow key={i}>
                                <TableCell sx={{pl:0}}>
                                    <Box component="span" sx={{color: getTypeColor(e.type)}}>{formatCurrency(amount)}</Box>
                                </TableCell>
                                <TableCell>{getTypeLabel(e)}</TableCell>
                                <TableCell>{e.variant}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>
                            </TableRow>
                        );
                    })}
                    {!records?.length && <TableRow><TableCell colSpan={6} sx={{pl:0}}>Keine Buchungen</TableCell></TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const DepositsListSimple = ({ deposits }) => {

    if (!deposits?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{pl:0}}>Betrag</TableCell>
                        <TableCell>Variante</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deposits.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell sx={{pl:0}}>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{e.variant}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpenditureListSimple = ({ expenditures }) => {

    if (!expenditures?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{pl:0}}>Betrag</TableCell>
                        <TableCell>Variante</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {expenditures.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell sx={{pl:0}}>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{e.variant}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const NotificationsListSimple = ({ notifications }) => {

    if (!notifications?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{pl:0}}>Betrag</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Variante</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell sx={{pl:0}}>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{getTypeLabel(e)}</TableCell>
                                <TableCell>{e.variant}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export { ExpenditureListSimple, DepositsListSimple, NotificationsListSimple, RecordsListSimple }
/*

Mangento => CC aus CH dann storniert der Shop die CC Zahlung und ändert auf Nachnahme

Zoll / Nachnahme / Portonachzahlung 

// Kdnr eingeben
// Maske "Neue Einlage"
// Betrag, Sendung, Datum, MA in DB
// optional: eQuittung


// Maske "Neue Auslage"
// Kdnr eingeben
// Prüfe DB nach Einträgen - Liste 
=> Weiter:
// Erzeugt DB Eintrag
// Erzeugt PDF
=>> Nicht weiter:
Email an Kunde, mit Betrag und Sendungsnummer


Bei Sendungsabholung:


optional: eQuittung


*/

const formatCurrency = (amount) => {
    return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount / 100)
};