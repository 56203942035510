import React, { useState, useEffect } from 'react'

import {
  Typography,
  Alert,
  Box,
  IconButton,
  Link,
}  from '@mui/material';

import SyncIcon from '@mui/icons-material/Sync';

import { DataGrid } from '@mui/x-data-grid';

const CustomerTransactions = ({customerTransactions}) => {

  
    console.log({customerTransactions})
  
    const columns = [
      {
        field: 'timestamp',
        headerName: 'Wann',
        type: 'timestamp',
        width: 180,
        renderCell: ({value}) => <span>{new Date(value).toLocaleDateString('de-DE', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        })}</span>,
      },
      { 
        field: 'type', 
        headerName: 'Was', 
        width: 130,
        valueGetter: (params) => {
          if(params.row.type === 'payment') return 'Abholung'
          if(params.row.type === 'bonus') return 'Bonus-Guthaben'
          if(params.row.type === 'deposit') return 'Einzahlung'
          if(params.row.type === 'adjustment') return 'Guthaben-Anpassung'
          if(params.row.type === 'free') return 'Guthaben Gutschrift'
        },
        sortable: false,
      },
      {
        field: 'amount',
        headerName: 'Betrag',
        width: 110,
        type: 'number',
        renderCell: ({value}) => value > 0 ? <Typography sx={{color:'green', textAlign:'right'}}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value/100)}</Typography> : <Typography sx={{color:'darkred', textAlign:'right'}}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value/100)}</Typography>,
        
        sortable: false,
      },
      {
        field: 'details',
        headerName: 'Details',
        width: 250,
        renderCell: (params) => {
          if(params.row.type === 'payment') return <Link target="_blank" href={'/mein-guthaben/downloadinvoice?name=downloadinvoice&paymentId='+params.row.abholId}>Rechnung</Link>
          if(params.row.type === 'bonus') return 'Bonus für Einzahlung: '+params.row.paymentId
          if(params.row.type === 'deposit') return 'Einzahlung: '+params.row.paymentId
          if(params.row.type === 'adjustment') return params.row.comment_public
          if(params.row.type === 'free') return params.row.comment_public
        },
        sortable: false,
      }
    ];
    
  
    return (
      <Box>

        <Box>
          { customerTransactions.length === 0 && <Alert sx={{mb:2, mt:2}}>Es gibt bisher keine Transaktionen.</Alert> }
          { customerTransactions.length > 0 && 
              <Box sx={{ height: 660, width: '100%', mt:4 }}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'timestamp', sort: 'desc' }],
                    },
                  }}
                  rows={customerTransactions}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                  getRowId={(row) =>  row.abholId + row.paymentId + row.transactionId + row.timestamp}
                />
            </Box>
          }
        </Box>
      </Box>
    )
}

export default CustomerTransactions