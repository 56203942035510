import { Cancel, Check } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, CircularProgress, Container, Dialog, FormControlLabel, Modal, Paper, Table, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';

const CustomerRegistration = ({ user }) => {

    const [open, setOpen] = useState();
    return (
        <>
            {open && <RegistrationPopup user={user} onClose={() => setOpen(false)} />}
            <Button onClick={() => setOpen(true)} variant="outlined">Neukunden-Erfassung</Button>
        </>
    );
};

export default CustomerRegistration;

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const RegistrationPopup = ({ user, onClose }) => {

    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const { station, secret, staff } = cookies;

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: station,
                secret: secret,
                staff: staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const [image, setImage] = useState(false);
    const [s3key, setS3Key] = useState(false);
    const [pending, setPending] = useState(false);
    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data);
        console.log({ lastMessage })
        if (msg.task === 'finishSignRegistrationForm') {
            const { message } = msg
            console.log({ message, msg })
            setImage(msg.image);
            setS3Key(msg.s3Key);
            setPending(false);

        }

    };

    const registrationData = { firstname: user.firstname, lastname: user.lastname, birthdate: user.birthdate };
    const kdnr = user.kdnr;
    const email = user.email;

    const sendMessageGetSignature = () => {
        setPending(true);
        const msg = { task: 'signRegistrationForm', station, staff, secret, kdnr, userdata: {kdnr}, passportData, registrationData, email };
        sendMessage(JSON.stringify(msg));
    };

    const sendMessageActivateUser = () => {
        setPending(true);
        const msg = { task: 'signRegistrationFormActivate', station, staff, secret, kdnr, userdata: {kdnr, ...passportData}, passportData, registrationData, email, s3key};
        sendMessage(JSON.stringify(msg));
    };

    const [open, setOpen] = useState(false);

    const [passportData, setPassportData] = useState({});
    const [validationResult, setValidationResult] = useState({});
    const [lockedInput, setLockedInput] = useState(false);

    useEffect(() => {
        setOpen(true);
    }, []);

    const up = (str) => {
        return str;
    };

    const validateData = () => {
        const result = {
            ...['firstname', 'lastname', 'birthdate'].reduce((p, c) => {
                p[c] = (up(passportData[c]) === up(registrationData[c]));
                p.passed = true && p.passed && (up(passportData[c]) === up(registrationData[c]))
                return p;
            }, { passed: true }),
            ready: true,
        };
        console.log(result)
        setLockedInput(true);
        setValidationResult(result);
    };

    const resetClose = () => {
        setPassportData({});
        onClose();
    };


    const [updateProfile, setUpdateProfile] = useState({});
    const [allowedReason, setAllowedReason] = useState(false);



    const submitValidator = () => {
        const rules = [
            {
                rule: (validationResult.firstname || updateProfile.firstname),
                errMsg: 'Vorname'
            },
            {
                rule: (validationResult.lastname || updateProfile.lastname),
                errMsg: 'Nachname'
            },
            {
                rule: (validationResult.birthdate || updateProfile.birthdate),
                errMsg: 'Geburtsdatum'
            },
            {
                rule: Object.keys(updateProfile).length > 0 ? allowedReason !== false : true,
                errMsg: 'Erlaubter Änderungsgrund'
            }
        ];
        let errors = [];
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (!rule.rule) {
                errors.push(rule.errMsg);
                console.log({ rule, validationResult, updateProfile })
            }
        }
        return {
            succeeded: errors.length === 0,
            errors
        }
    };

    const { errors, succeeded } = submitValidator();

    return (
        <Dialog onClose={() => resetClose()} open={open} sx={{ '.MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.90)' } }} PaperProps={{ sx: { width: '100%', p: 0, m: 0 } }} maxWidth="xl" >

            <Paper sx={{ p: 2, minHeight: '730px' }}>
                <Typography variant='h6' gutterBottom>Neukunden Registrierung</Typography>
                {!image && <Box>
                    <Alert severity='info'>
                        <Typography>Die nachfolgenden Daten müssen <b>direkt vom Ausweis</b> abgelesen werden!</Typography>
                    </Alert>
                    <Box sx={{ display: 'flex', py: 2, gap: 2 }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
                            <TextField autoComplete='off' disabled={lockedInput} label="Vorname" onChange={(evt) => setPassportData({ ...passportData, firstname: evt.target.value })} />
                            {validationResult.ready && <>
                                {validationResult.firstname ? <Check color='success' /> : <Cancel color='error' />}
                                {!validationResult.firstname && <>
                                    Angegeben: {registrationData.firstname}
                                    <FormControlLabel control={<Checkbox onClick={(evt) => setUpdateProfile({ ...updateProfile, firstname: evt.target.checked })} />} label={"Profil ändern zu " + passportData.firstname} />
                                </>}
                            </>}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
                            <TextField autoComplete='off' disabled={lockedInput} label="Nachname" onChange={(evt) => setPassportData({ ...passportData, lastname: evt.target.value })} />
                            {validationResult.ready && <>
                                {validationResult.lastname ? <Check color='success' /> : <Cancel color='error' />}
                                {!validationResult.lastname && <>
                                    Angegeben: {registrationData.lastname}
                                    <FormControlLabel control={<Checkbox onClick={(evt) => setUpdateProfile({ ...updateProfile, lastname: evt.target.checked })} />} label={"Profil ändern zu " + passportData.lastname} />
                                </>}
                            </>}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
                            <TextField autoComplete='off' disabled={lockedInput} label="Geburtsdatum" onChange={(evt) => setPassportData({ ...passportData, birthdate: evt.target.value })} />
                            {validationResult.ready && <>
                                {validationResult.birthdate ? <Check color='success' /> : <Cancel color='error' />}
                                {!validationResult.birthdate && <>
                                    Angegeben: {registrationData.birthdate}
                                    <FormControlLabel control={<Checkbox onClick={(evt) => setUpdateProfile({ ...updateProfile, birthdate: evt.target.checked })} />} label={"Profil ändern zu " + passportData.birthdate} />
                                </>}
                            </>}
                        </Box>
                    </Box>
                    {validationResult.ready !== true && <Button variant="outlined" onClick={() => validateData()}>Daten abgleichen</Button>}
                    {validationResult.ready && validationResult.passed !== true &&
                        <Alert severity='warning' sx={{ mt: 2 }}>
                            <Typography>Das Profil darf nur in folgenden Fällen aktualisiert werden: </Typography>
                            <ul>
                                <li>Offensichtlicher Schreibfehler (z.B. Mustemann anstatt Mustermann)</li>
                                <li>Nickname/Spitzname/Rufname anstatt richtiger Name (z.B. Tommy anstatt Thomas)</li>
                                <li>Unvollständiger Name (z.B. Nakovich anstatt Nakovich-Meier)</li>
                            </ul>
                            <FormControlLabel control={<Checkbox onClick={(evt) => setAllowedReason(evt.target.checked)} />} label="Die Änderung betrifft einen der erlaubten Gründe" />
                        </Alert>
                    }
                    <Box sx={{ py: 2 }}>
                        <Button disabled={!succeeded} variant="outlined" onClick={() => sendMessageGetSignature()}> {pending && <CircularProgress size={14} sx={{ mr: 1 }} />} Unterschrift einholen</Button>
                    </Box>
                </Box>}

                {image && <Box>
                    <Box>
                        <Box component="img" src={image} sx={{ width: '200px', p: 2, my: 4, border: 'solid 2px gray' }} />
                    </Box>
                    <Button variant="contained" color="success" onClick={() => sendMessageActivateUser()}>Speichern & Kunde aktivieren</Button> <br /><br />
                    <Button variant="outlined" color="error" onClick={() => sendMessageGetSignature()}>Neue Unterschrift anfordern</Button>
                </Box>}

            </Paper>

        </Dialog>
    );
};

