import React, { useEffect, useState } from "react";

import { BrowserRouter, useNavigate } from "react-router-dom";

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home'
import Checkout from './components/Checkout'
import Setup, {SignIn} from './components/Setup'
import { Box, Typography, Container, AppBar, Toolbar, IconButton, Button, TextField, Alert, Card, ThemeProvider, createTheme, Input} from "@mui/material";

import { CookiesProvider, useCookies } from 'react-cookie';
import { Outlet } from "react-router-dom";
import Admin from './components/Admin'

import useWebSocket, { ReadyState } from 'react-use-websocket';

import logo from './mps.png'

import LogoutIcon from '@mui/icons-material/Logout';
import InputIcon from '@mui/icons-material/Input';
import { createBrowserHistory } from 'history'
import ResearchHome from "./components/Research";
import ShipmentSearchWarehouse from "./components/ShipmentSearchWarehouse";
import OptimizeWarehouse from "./components/OptimizeWarehouse";
import SeparateEscrowAccount from "./components/SeparateEscrowAccount";
import PaymentJournal from "./components/PaymentJournal";
import Checkin from "./components/Checkin";
import Storage from "./components/Storage";
import DeviceManagement from './components/DeviceManagement'
import SelfCheckoutManager from './components/SelfCheckoutManager'
import ShipmentsJournal from './components/ShipmentsJournal'
import { LocalShipping, ZoomIn } from "@mui/icons-material";
import Explorer from "./components/Explorer";
import Checkin2 from "./components/Checkin2";

const replaceHashPath = () => {
  const history = createBrowserHistory()
  const hash = history.location.hash
  if (hash) {
    const path = hash.replace(/^#/, '')
    if (path) {
      history.replace(path)
    }
  }
}
replaceHashPath()


const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const Layout = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['staff', 'staffName'])
  const navigate = useNavigate()

  const handleLogout = () => {
    removeCookie('staff')
    removeCookie('staffName')
    
    navigate('/logout')
  }

  const CustomFontTheme = createTheme({
    typography: {
      fontSize: 14,
      
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // target the disabled state of the root element of OutlinedInput
            '&.Mui-disabled': {
              color: '#666', // Change as needed
              cursor: 'default'
            }
          },
          input: {
            // target the disabled state of the input element inside OutlinedInput
            '&.Mui-disabled': {
              opacity: 1,
              WebkitTextFillColor: '#666' // Change as needed
            }
          }
        }
      }
    }

  });

  return (
    <ThemeProvider theme={CustomFontTheme}>
      <Box sx={{backgroundColor: 'lightgrey', minHeight: '100vh'}}>
        <Box sx={{ flexGrow: 1, minHeight: '80px' }}>
          <AppBar position="static">
            <Toolbar sx={{gap:1}}>
              <Typography variant="h6" component="div">
                <Box component="img" sx={{width:'50px', mr: 2, mt: '5px'}} src={logo}  />
              </Typography>
              <Button variant="outlined" startIcon={<LocalShipping />} color="inherit" to="/checkin" component={Link}>
                Erfassung
              </Button>
              <Button variant="outlined" startIcon={<InputIcon />} color="inherit" to="/storage" component={Link}>
                Ein/Umlagerung
              </Button>
              <Button variant="outlined" startIcon={<LogoutIcon/>} color="inherit" to="/checkout" component={Link}>
                Sendungsausgabe
              </Button>
              <Button variant="outlined" startIcon={<ZoomIn />} color="inherit" to="/recherche" component={Link}>
                Recherche
              </Button>
              <Box sx={{ flexGrow: 1 }}></Box>
              {cookies?.staffName && <Box component="span" sx={{border:'solid 1px red', p: 1, backgroundColor: 'white', color:'black' }}>{cookies?.staffName}</Box>}
              <Button sx={{ml: 2}} variant="outlined" onClick={handleLogout} color="inherit">Logout</Button>
            </Toolbar>
          </AppBar>
        </Box>
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </Box>
    </ThemeProvider>
  )
}


const App = () => {

  return (
    
    <CookiesProvider>
      <Environment />
    </CookiesProvider>
  
  );
}

export default App;


const Environment = () => {

  const [cookies] = useCookies(['station', 'secret']);
  
  const posConfig = {
    type: 'checkout',
    connection: {
      station: cookies.station,
    }
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<EnforceLoggedin posConfig={posConfig}><Home /></EnforceLoggedin>} />
            <Route path="signin/*" element={<SignIn />} />
            <Route path="checkout/*" element={<EnforceLoggedin posConfig={posConfig}><Checkout posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="checkin/*" element={<EnforceLoggedin posConfig={posConfig}><Checkin posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="checkin2/*" element={<EnforceLoggedin posConfig={posConfig}><Checkin2 posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="explorer/*" element={<EnforceLoggedin posConfig={posConfig}><Explorer posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="storage/*" element={<EnforceLoggedin posConfig={posConfig}><Storage posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="escrowaccounts/*" element={<EnforceLoggedin posConfig={posConfig}><SeparateEscrowAccount posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="paymentjournal/*" element={<EnforceLoggedin posConfig={posConfig}><PaymentJournal posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="paymentjournal/*" element={<EnforceLoggedin posConfig={posConfig}><PaymentJournal posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="journal/shipments/*" element={<EnforceLoggedin posConfig={posConfig}><ShipmentsJournal posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="devicemanagement/*" element={<EnforceLoggedin posConfig={posConfig}><DeviceManagement posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="selfcheckout/*" element={<EnforceLoggedin posConfig={posConfig}><SelfCheckoutManager posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="recherche" element={<EnforceLoggedin posConfig={posConfig}><ResearchHome posConfig={posConfig} /></EnforceLoggedin>} />
            <Route path="setup/*" element={<Setup posConfig={posConfig} />} />
            <Route path="warehouse/*" element={<ShipmentSearchWarehouse posConfig={posConfig} />} />
            <Route path="warehouse/optimize/*" element={<OptimizeWarehouse posConfig={posConfig}/>} />
            <Route path="admin/*" element={<Admin posConfig={posConfig} />} />
            <Route path="admin/authorize/:auth" element={<Admin posConfig={posConfig} />} />
            <Route path="logout" element={<Logout />}/>
            <Route path="reset" element={<Reset />}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

const Reset = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['station', 'secret', 'staff', 'staffName']);
  const navigate = useNavigate();

  const handleReset = () => {
    removeCookie('station');
    removeCookie('secret');
    removeCookie('staff');
    removeCookie('staffName');
    navigate('/')
  }

  return <Button color="warning" onClick={handleReset}>System Logout</Button>
}


const Logout = () => {
  useEffect(()=>{
    window.location.replace('/checkout');
  },[])
  return (<></>)
}

const EnforceLoggedin = ({children, posConfig}) => {
  
  const navigate = useNavigate()

  const [cookies, setCookie] = useCookies(['station', 'secret', 'staff', 'staffName']);
  
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  
  const [isLoggedIn, setIsLoggedIn] = useState(cookies.staff);
  const [isLoginError, setIsLoginError] = useState(false)


  useEffect(()=>{
    if(! cookies.secret){
      navigate('/signin')
    }
  },[])

  const { sendMessage, readyState } = useWebSocket(socketUrl, 
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );

  const staffLogin = (username, password) => {
    sendMessage(JSON.stringify({task: 'staffLogin', station: posConfig.connection.station, username, password}))
  }
  

  const handleWebsocketEvent = (lastMessage) => {

    const msg = JSON.parse(lastMessage.data)
    console.log({msg})

    if(msg.task==='respStaffLogin'){
      
      const {succeeded, id, error, name} = msg
      setIsLoggedIn(id)
      setIsLoginError(error)
      if(succeeded){
        setCookie('staff', id, { path: '/' })
        setCookie('staffName', name, { path: '/' })
      }
    }
    
  }

  const handleLogin = () => {
    staffLogin(username, password)
  }


  if( ! isLoggedIn ){
    return (
      <Box>
        { !! isLoginError && <Alert severity="error" sx={{mb: 4}}>{isLoginError}</Alert>}
        <Card sx={{p:2, mt:2}}>
          <Box sx={{mb:2}}>
              <TextField size="small" onChange={(evt)=>setUsername(evt.target.value)} label="Benutzername" /> 
              <TextField sx={{ml:2}} size="small" onChange={(evt)=>setPassword(evt.target.value)} label="Passwort" type={"password"}/>
          </Box>
          <Button variant="outlined" onClick={handleLogin}>Login</Button>
        </Card>
      </Box>
    )
  }
  
  return <>{ !! cookies.secret && <>{children}</>}</>
}












function md5(inputString) {
  var hc="0123456789abcdef";
  function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
  function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
  function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
  function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
  function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
  function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
  function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
  function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
  function sb(x) {
      var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
      for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
      blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
  }
  var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
  for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
      a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
      b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
      c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
      d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
      a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
      b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
      c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
      d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
      a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
      b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
      c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
      d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
      a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
      b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
      c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
      d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
      a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
      b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
      c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
      d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
      a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
      b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
  }
  return rh(a)+rh(b)+rh(c)+rh(d);
}
