import React, { useEffect, useState, useRef } from "react";
import { Link, Box, Button, Checkbox, Modal, Typography, Container, createTheme, CssBaseline, Avatar, ThemeProvider, Card, Alert } from "@mui/material";

import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import QRCode from "react-qr-code";

import {LockOutlined as LockOutlinedIcon}  from '@mui/icons-material'
import { useNavigate } from "react-router-dom";

const SIGNATURE_APP_BASE = 'https://signature.pos.mpsapi.de/'
const CHECKOUT_APP_BASE = 'https://checkout.pos.mpsapi.de/'
const Setup = () => {

  return (
    <>
      <SetupLink />    
    </>
  )
}

export default Setup


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};


const SetupLink = () => {

  const [cookies, setCookie] = useCookies(['station']);

  const [showModal, setShowModal]= useState(false)
  const [checked, setChecked] = useState(false) 

  const handlerReplaceLink = () => {
  
    const expires = new Date(Date.now()+60 * 60 * 24 * 366 * 1000)

    setCookie('station', uuid().substr(0,8), { path: '/', expires})
    alert('Link wurde ersetzt! Signatur-App muss neu verbunden werden!')
    setShowModal('qrcode')
  }

  const handlerDisplayLink = () => {
    //cookies.station
    setShowModal('qrcode')
  }

  const handleCheckbox = (evt) => {
    setChecked(evt.target.checked)
  }

  return <Layout><Box>
    { ! cookies.station && <>
      <Button sx={{mb:2}} variant="contained" color="success" onClick={handlerReplaceLink}>Signatur-App-Link erzeugen</Button>
    </>}
    { !! cookies.station && <>
      <Checkbox onChange={handleCheckbox}/> Ich möchte einen neuen Verbindungskanal benutzen
      <br />
      <Button sx={{mb:2}} variant="contained" color="error" disabled={!checked} onClick={handlerReplaceLink}>Signatur-App-Link ersetzen</Button>
      <br />
      <Button sx={{mb:2}} variant="contained" onClick={handlerDisplayLink}>Signatur-App-Link anzeigen</Button>
    </> }
    <Modal
      open={!!showModal}
      onClose={()=>setShowModal(false)}
      >
      <Box sx={modalStyle}>
        { ! cookies.station && <>Zuerst App Link erzeugen</> }
        { !! cookies.station && showModal === 'qrcode' && <><Typography variant="h5" gutterBottom>Bitte mit dem Signatur-Pad scannen</Typography><QRCode level="M" value={SIGNATURE_APP_BASE + 'setup/use/' + cookies.station} /><Typography sx={{mt: 1,fontSize:'0.7rem',fontFamily: 'Courier,Courier New'}}>{cookies.station}</Typography><Typography sx={{mt: 1,fontSize:'0.7rem',fontFamily: 'Courier,Courier New'}}>{SIGNATURE_APP_BASE + 'setup/use/' + cookies.station}</Typography></> }
      </Box>
    </Modal>
  </Box></Layout>

}




const SignIn = () => {

  const [cookies, setCookie] = useCookies(['secret']);
  const [showQr, setShowQr] = useState(false)
  const [newSecret, setNewSecret] = useState('XMPSA' + uuid()+uuid()+uuid()+uuid())
  
  const handleDisplayCode = () => {
    const timestamp = new Date().getTime();
    const expires = new Date(Date.now()+60 * 60 * 24 * 366 * 1000)

    setCookie('secret', newSecret, { path: '/', expires })
    setShowQr(true)
  }

  const navigate = useNavigate();

  return <Layout>
     <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Gerät registrieren
      </Typography>
    <Container>
    <Box sx={{ mt: 4, p:2 }}>
      {showQr 
        ? ( 
          <>
            <Link href={'/admin/authorize/' + newSecret}>
              <QRCode value={CHECKOUT_APP_BASE + 'admin/authorize/' + newSecret}/>
            </Link>
            <Alert severity='warning'>Als erstes den Registrierungscode scannen, dieser wird nur jetzt angezeigt!<br/>Der Code muss mit einem <b>Admin-Handy</b> gescannt werden.</Alert>
          </>
        ) : (
          <Button variant="contained" color="success" onClick={handleDisplayCode}>Registrierungscode anzeigen</Button>
        )
      }
      <Button onClick={()=>navigate('/setup')}>Einrichtung fortsetzen</Button>
    </Box>
  </Container></Layout>
}

export {SignIn}

const Layout = ({children}) => {

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Card sx={{textAlign:'center', mt: 2}}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
            {children}
          </Box>
        </Card>
      </Container>
    </ThemeProvider>
  )
}