import { Box, Button, Chip, Container, LinearProgress, Modal, Paper, Typography } from "@mui/material";
import { ShipmentHistoryButton } from "./Checkout";
import { ContactEmergency, East, ViewTimeline } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";


const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const WarehouseSlotHistory = ({ slot }) => {

    const [cookies] = useCookies(['secret', 'station', 'staff', 'checkinDeviceId']);

    const [slotHistoryData, setSlotHistoryData] = useState(false);
    const [next, setNext] = useState();

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: cookies.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );


    useEffect(() => {
        setSlotHistoryData(false);
        setNext();
        getSlotHistory(slot);
    }, [slot])

    const handlerLoadMore = () => {
        getSlotHistory(slot, next);
    };

    const getSlotHistory = (slot, next) => {
        sendMessage(JSON.stringify({ task: 'getJournalForSlot', station: cookies.station, staff: cookies.staff, slot, did: cookies.checkinDeviceId, next }))
    };

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respGetJournalForSlot' && msg.status === "succeeded" && msg.isNext === false) {
            setSlotHistoryData(msg);
            setNext(msg.next)
        }

        if (msg.task === 'respGetJournalForSlot' && msg.status === "succeeded" && msg.isNext === true) {
            setSlotHistoryData({
                history: [...slotHistoryData.history, ...msg.history],
                warehouseInfo: [...slotHistoryData.warehouseInfo, ...msg.warehouseInfo],
                staffInfo: [...slotHistoryData.staffInfo, ...msg.staffInfo]
            });
            setNext(msg.next)
        }

    };

    const staffMap = slotHistoryData?.staffInfo?.reduce((p, c) => {
        p[c.id] = c.vorname + ' ' + c.nachname;
        return p;
    }, {});

    const warehouseMap = slotHistoryData?.warehouseInfo?.reduce((p, c) => {
        p[c.id] = c.text;
        return p;
    }, {});

    if (slotHistoryData === false) return <LinearProgress variant="indeterminate" />
    return (
        <Box>
            <WarehouseSlotHistoryView slot={slot} history={slotHistoryData.history} warehouseMap={warehouseMap} staffMap={staffMap} />
            {next && <Button onClick={handlerLoadMore}>Mehr laden</Button>}
        </Box>
    );
};


export default WarehouseSlotHistory;


const WarehouseSlotHistoryView = ({ slot, history, warehouseMap, staffMap, preview }) => {

    if (!history.length) return <LinearProgress variant='indeterminate' />

    return (

        <Paper sx={{ p: 4, m: 0, backgroundColor: 'lightgray' }}>
            <Typography variant="h4" gutterBottom>Verlaufx</Typography>
            {history.map((e, i) => {
                return (
                    <Box key={i} sx={{ backgroundColor: 'white', my: 1, px: 2, py: 1 }}>
                        {e.mpsid} <ShipmentHistoryButton id={e.mpsid} />  <East sx={{ fontSize: '12px' }} /> <Chip label={e.action==="CHECKOUT"?"Kunde":warehouseMap[e.lager_id]} /> am {new Date(e.timestamp).toLocaleString('de-DE')} von {staffMap[e.staff]} ({e.did})
                    </Box>
                );
            })}
            {preview && <>
                <WarehouseModalButton slot={slot} />
            </>
            }
        </Paper>
    );
};

export { WarehouseSlotHistoryView };


const WarehouseModalButton = ({ slot }) => {

    const [openAll, setOpenAll] = useState(false);

    return (
        <>
            <Button sx={{ mt: 2 }} onClick={() => setOpenAll(true)} variant="outlined">Alle anzeigen</Button>
            <Modal open={openAll} onClose={() => setOpenAll(false)} sx={{ overflow: 'scroll' }}>
                <Container maxWidth="lg" >
                    <Paper sx={{ p:1,mt: 4 }}>
                        <WarehouseSlotHistory slot={slot} />
                    </Paper>
                </Container>
            </Modal>
        </>
    );
};

export { WarehouseModalButton };

const WarehouseModalIconButton = ({ slot }) => {

    const [openAll, setOpenAll] = useState(false);

    return (
        <>
            <Button variant="outlined" startIcon={<ViewTimeline />} onClick={() => setOpenAll(true)} >Verlauf</Button>
            <Modal open={openAll} onClose={() => setOpenAll(false)} sx={{ overflow: 'scroll' }}>
                <Container maxWidth="lg" >
                    <Paper sx={{p:1, mt: 4 }}>
                        <WarehouseSlotHistory slot={slot} />
                    </Paper>
                </Container>
            </Modal>
        </>
    );
};

export { WarehouseModalIconButton };


