import { Box, Typography } from "@mui/material"


const Home = () => {

    return (
        <Box sx={{textAlign:'center'}}>
          <Typography variant="h1">MyPaketshop</Typography>
          <img src="https://www.mypaketshop.com/wp-content/uploads/MyPaketshop_Bildmarke-512.png" />
          <Typography variant="h2">Sendungsmanager</Typography>
        </Box>
    )
    
}

export default Home