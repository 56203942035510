import { Chip, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';
import { Timeline, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineOppositeContent } from '@mui/lab'
import { East, LocalShipping, Logout } from '@mui/icons-material';
import InputIcon from '@mui/icons-material/Input';
const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const LagerplatzDetails = ({ sid }) => {

    const [details, setDetails] = useState();
    const [history, setHistory] = useState();

    const [cookies] = useCookies(['secret', 'station', 'staff'])

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: cookies.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );


    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respGetShipmentDetailsAndHistory') {
            const { history, details } = msg;
            setDetails(details);
            setHistory(history);
        }
    }

    useEffect(() => {
        sendMessage(JSON.stringify({ task: 'getShipmentDetailsAndHistory', station: cookies.station, staff: cookies.staff, sid }))
    }, []);


    if (!details) return (<LinearProgress variant='indeterminate' />)

    return (<>
        <Typography variant="h4">Verlauf</Typography>
        <Timeline position="left">
            {history.sort((a,b)=>new Date(a.timestamp).getTime()-new Date(b.timestamp).getTime()).map((e, i) => {
                return (
                    <TimelineItem key={i}>
                        <TimelineOppositeContent sx={{pt: 3}}>
                            {new Date(e.timestamp).toLocaleString('de-DE')}
                            <Typography variant="caption" component="p">{e.staffName} {e.did && <>({e.did})</>}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                                {getActionIcon(e.action)}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{py: 2}}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {getActionName(e.action)}
                            </Typography>
                            <Typography><Chip label={e.previousLagerName} size="small" /> <East sx={{fontSize:'16px'}} /> <Chip label={e.lagerName} size="small" /></Typography>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>

        Letzter MA: {details.creator}<br />
    </>);
};

export default LagerplatzDetails;


const getActionName = (key) => {
    return { 'CHECKIN': 'Erfassung', 'RELOCATE': 'Ein-/Umlagerung', 'CHECKOUT': 'Ausgabe' }[key];
}

const getActionIcon = (key) => {
    return { 'CHECKIN': <LocalShipping />, 'RELOCATE': <InputIcon />, 'CHECKOUT': <Logout /> }[key];
}