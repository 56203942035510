import { Alert, Box, Button, Checkbox, Collapse,  LinearProgress, MenuItem,  Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import {RecordsListSimple } from './SeparateEscrowAccount';
import { EscrowAccountSignature } from './Signature';
const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const SeparateEscrowCheckout = ({ posConfig, kdnr, signatureName }) => {

    const [data, setData] = useState();

    const [newId, setNewId] = useState();

    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [lastRequestId, setLastRequestId] = useState(0)

    useEffect(() => {
        setData();
        if (kdnr) {
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId)
        }
    }, [kdnr]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetSeparateEscrowAccount') {

            const { expenditures, deposits, requestId } = msg;

            if (requestId === lastRequestId) {
                setData({ expenditures, deposits });
                setSelectedDeposits(deposits);
                setSelectedExpenditures(expenditures);
            };

        };
        /*
                if (msg.task === 'respSeparateEscrowAccountCheckout') {
        
                    const { expenditures, deposits, requestId } = msg;
        
                    if (requestId === lastRequestId) {
                        setData({expenditures, deposits});
                        setSelectedDeposits(deposits);
                        setSelectedExpenditures(expenditures);
                    };
        
                };
        */
        if (msg.task === 'respPutSeparateEscrowAccountEntry') {
            const { id, pdfUrl, notificationSent } = msg;
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId);
            setNewId(pdfUrl || notificationSent);
            if (pdfUrl) {
                window.open(pdfUrl);
            }
        };

        if (msg.task === 'respSeparateEscrowAccountCheckout') {
            const { expenditures, deposits, pdfUrl } = msg;
            setData({ expenditures, deposits });
            setSelectedDeposits(deposits);
            setSelectedExpenditures(expenditures);
            console.log({ msg })
            if (pdfUrl) {
                window.open(pdfUrl);
            }
        }
        if (msg.task === 'respPutSeparateEscrowAccountDeposit') {
            const { expenditures, deposits } = msg;
            setData({ expenditures, deposits })
        }


    };

    const getSeparateEscrowAccount = (thisRqId) => {
        sendMessage(JSON.stringify({ task: 'getSeparateEscrowAccount', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, kdnr, status: 'open' }))
    }

    const separateEscrowAccountCheckout = ({ closeRecords, newDeposit, payedOut, payedIn }) => {
        sendMessage(JSON.stringify({ task: 'separateEscrowAccountCheckout', station: posConfig.connection.station, staff: cookies.staff, kdnr, user: cookies.staff, closeRecords, newDeposit, payedOut, payedIn, signatureName }))
    }

    const resetHandler = () => {
        setNewId();
        setData();
    }

    const [selectedExpenditures, setSelectedExpenditures] = useState([]);
    const [selectedDesposits, setSelectedDeposits] = useState([]);

    const expedituresSum = selectedExpenditures?.reduce((p, c) => {
        p = p + c.amount * 1;
        return p;
    }, 0);

    const depositsSum = selectedDesposits?.reduce((p, c) => {
        p = p + c.amount * 1;
        return p;
    }, 0);

    const totalSum = (depositsSum - expedituresSum) * -1;

    const handlerSubmit = (payedOut, newDeposit, payedIn) => {

        

        const closeRecords = [
            ...selectedExpenditures.map(e => ({ id: e.id, status: 'closed' })),
            ...selectedDesposits.map(e => ({ id: e.id, status: 'closed' }))
        ].filter(e => !!e);

        console.log({ closeRecords, newDeposit })

        separateEscrowAccountCheckout({ closeRecords, newDeposit, payedOut, payedIn });
    };

    const [open, setOpen] = useState(true);

    return (
        <>
            <EscrowAccountSignature />
            {!data && <Paper sx={{ p: 4, mb: 2, minHeight: '1.2rem' }}><LinearProgress variant="indeterminate" /></Paper>}
            {data && (data?.expenditures?.length > 0) === false && <Paper sx={{ p: 4, mb: 2 }}>
                Keine Auslagen.
            </Paper>}
            {(data?.expenditures?.length > 0) === true && <Paper sx={{ p: 4, mb: 2 }}>
                {!open && <Button onClick={() => setOpen(true)}>Einlagen anzeigen</Button>}
                <Collapse in={open}>
                    <Box>
                        <Box sx={{ display: 'flex', gap: 4 }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h6">Auslagen</Typography>
                                <Typography variant="caption">{formatCurrency(expedituresSum)}</Typography>
                                <ExpenditureList selected={selectedExpenditures} setSelected={setSelectedExpenditures} expenditures={data.expenditures.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))} />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h6">Einlagen</Typography>
                                <Typography variant="caption">{formatCurrency(depositsSum)}</Typography>
                                <DepositsList selected={selectedDesposits} setSelected={setSelectedDeposits} deposits={data.deposits.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))} />
                            </Box>
                        </Box>
                        <Box sx={{ border: 'dashed 4px darkgray', backgroundColor: 'lightyellow', p: 2, borderRadius: 2, my: 2 }}>
                            <>
                                {totalSum > 0 && <>
                                    <Typography variant="body2">Kunde muss mit Bargeld zahlen</Typography>
                                    <Typography variant="h3">{formatCurrency(totalSum)}</Typography>
                                </>}
                                {totalSum === 0 && <>
                                    <Typography variant="body2">Keine Zahlung erfoderlich</Typography>
                                    <Typography variant="h3">Bitte Ein- und Auslagen verrechnen</Typography>
                                </>}
                                {totalSum < 0 && <>
                                    <Typography variant="body2">Kunde bekommt Bargeld ausgezahlt</Typography>
                                    <Typography variant="h3">{formatCurrency(totalSum * -1)}</Typography>
                                </>}
                            </>
                        </Box>
                        {totalSum < 0 && <Box sx={{ gap: 2, display: 'flex' }}>
                            <Button variant="contained" onClick={() => handlerSubmit(totalSum * -1, 0, 0)}>{formatCurrency(totalSum * -1)} wurde ausgezahlt</Button>
                            <Button variant="outlined" onClick={() => handlerSubmit(0, totalSum * -1, 0)}>{formatCurrency(totalSum * -1)} wurde behalten</Button>
                        </Box>}

                        {totalSum > 0 && <Box sx={{ gap: 2, display: 'flex' }}>
                            <Button variant="contained" onClick={() => handlerSubmit(0, 0, totalSum)}>Kunde hat bezahlt</Button>
                        </Box>}

                        {totalSum === 0 && <Box sx={{ gap: 2, display: 'flex' }}>
                            <Button variant="contained" onClick={() => handlerSubmit(0, 0, totalSum)}>Jetzt verrechnen</Button>
                        </Box>}
                    </Box>
                </Collapse>

            </Paper>}

        </>
    );
};

export default SeparateEscrowCheckout;


const DepositsList = ({ deposits, selected, setSelected }) => {


    const [editMode, setEditMode] = useState(false);

    if (!deposits?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Button size='small' onClick={() => setEditMode(true)}>Bearbeiten</Button></TableCell>
                        <TableCell>Betrag</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {deposits.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell>
                                    <Checkbox disabled={!editMode} checked={selected.filter(ee => e.id === ee.id).length === 1} onChange={
                                        (evt) => {
                                            if (evt.target.checked) {
                                                setSelected([...selected.filter(ee => ee.id !== e.id), e]);
                                            } else {
                                                setSelected(selected.filter(ee => ee.id !== e.id));
                                            }
                                        }
                                    } />
                                </TableCell>
                                <TableCell>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpenditureList = ({ expenditures, selected, setSelected }) => {

    const [editMode, setEditMode] = useState(false);

    if (!expenditures?.length) return;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Button size='small' onClick={() => setEditMode(true)}>Bearbeiten</Button></TableCell>
                        <TableCell>Betrag</TableCell>
                        <TableCell>Kommentar</TableCell>
                        <TableCell>Art</TableCell>
                        <TableCell>Datum</TableCell>
                        <TableCell>MA</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {expenditures.map((e, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell>
                                    <Checkbox disabled={!editMode} checked={selected.filter(ee => e.id === ee.id).length === 1} onChange={
                                        (evt) => {
                                            if (evt.target.checked) {
                                                setSelected([...selected.filter(ee => ee.id !== e.id), e]);
                                            } else {
                                                setSelected(selected.filter(ee => ee.id !== e.id));
                                            }
                                        }
                                    } />
                                </TableCell>
                                <TableCell>{formatCurrency(e.amount)}</TableCell>
                                <TableCell>{e.comment}</TableCell>
                                <TableCell>{e.variant}</TableCell>
                                <TableCell>{new Date(e.timestamp).toLocaleString('de-DE')}</TableCell>
                                <TableCell>{e.user}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const AddDeposit = ({ posConfig, kdnr }) => {


    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [lastRequestId, setLastRequestId] = useState(0)
    const [newId, setNewId] = useState();

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respPutSeparateEscrowAccountDeposit') {
            const { id, pdfUrl } = msg;
            setNewId(id);
            if (pdfUrl) {
                window.open(pdfUrl);
            }
        };
    };

    const putSeparateEscrowAccountDeposit = ({ thisRqId, status, variant, amount, comment }) => {
        sendMessage(JSON.stringify({ task: 'putSeparateEscrowAccountDeposit', station: posConfig.connection.station, staff: cookies.staff, user: cookies.staff, requestId: thisRqId, kdnr, status, variant, amount, comment }))
    }

    const handleDeposit = (props) => {
        setLastRequestId(props.thisRqId);
        putSeparateEscrowAccountDeposit(props);
    };

    const [open, setOpen] = useState(false);
    const dataInit = {
        variant: 'NONE',
        amount: "",
        comment: ""
    };

    const [newRecord, setNewRecord] = useState(dataInit);

    const [error, setError] = useState({});

    const reset = () => {
        setNewRecord(dataInit);
        setOpen(false);
        setNewId();
    };

    const handleChangeType = (event) => {
        const value = event.target.value;
        if (value === "NONE") {
            setError({
                ...error,
                type: 'Bitte gültigen Wert auswählen'
            })
        } else {
            setError({
                ...error,
                type: undefined
            });
        }
        setNewRecord({
            ...newRecord,
            variant: value
        });
    };

    const handleChangeAmount = (event) => {

        const string = event.target.value;
        const amount = validateNumber(string);

        if (amount) {
            setError({
                ...error,
                amount: undefined
            });
        } else {
            setError({
                ...error,
                amount: 'Bitte gültigen Wert eingeben'
            });
        }

        setNewRecord({
            ...newRecord,
            amount: string
        });
    };

    const handleChangeComment = (event) => {

        if (event.target.value.length > 10) {
            setError({
                ...error,
                comment: undefined
            });
        } else {
            setError({
                ...error,
                comment: 'Bitte gültigen Wert eingeben'
            });
        }
        setNewRecord({ ...newRecord, comment: event.target.value })
    };

    const validateNumber = (string) => {
        const cleanedString = string.replace(/\s/g, '').replace(/,/g, '.');
        if (/^\d+(\.\d{1,2})?$/.test(cleanedString)) {
            return parseFloat(cleanedString) * 100;
        } else {
            return false;
        }
    }

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const x = (validateNumber(newRecord.amount) > 0 && newRecord.variant !== 'NONE' && newRecord.comment.length > 10)
        setIsValid(x);
    }, [newRecord.amount, newRecord.variant, newRecord.comment]);

    const handleSubmit = () => {
        handleDeposit({ thisRqId: uuid(), status: 'open', type: 'deposit', variant: newRecord.variant, comment: newRecord.comment, amount: validateNumber(newRecord.amount) })
    };



    return (
        <Paper square sx={{ p: 4 }} variant='outlined'>
            {!open && <Button variant='outlined' onClick={() => setOpen(true)}>Neue Einlage</Button>}
            {open &&
                <Box>
                    {!newId &&
                        <Box>
                            <Typography gutterBottom>Neue Auslage ({kdnr})</Typography>
                            <Box sx={{ display: 'flex', gap: 2, pt: 2 }}>
                                <TextField autoComplete="false" label="Betrag" size="small" value={newRecord.amount} onChange={handleChangeAmount} error={!!error.amount} />
                                <TextField autoComplete="false" label="Kommentar" size="small" value={newRecord.comment} onChange={handleChangeComment} error={!!error.comment} />
                                <Select size='small' value={newRecord.variant} onChange={handleChangeType} error={!!error?.type}>
                                    <MenuItem value="NONE">Bitte wählen...</MenuItem>
                                    {
                                        ["Zollgebühren", "Nachnahme", "Porto-Nachzahlung", "Anderes"].map((e, i) => {
                                            return <MenuItem key={i} value={e}>{e}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Box>
                            <Box sx={{ border: 'dashed 4px darkgray', backgroundColor: 'lightyellow', p: 2, borderRadius: 2, my: 2 }}>
                                <Typography variant="body2">Kunde muss mit Bargeld zahlen</Typography>
                                <Typography variant="h3">{formatCurrency(validateNumber(newRecord.amount))}</Typography>
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <Button disabled={!isValid} onClick={handleSubmit} variant='contained'>Speichern</Button>
                            </Box>

                        </Box>

                    }
                    {newId && <Box>
                        {newId !== true && <Button onClick={() => window.open(newId)}>Beleg drucken</Button>}
                        {newId === true && <Alert severity='info'>Kunde wurde informiert.</Alert>}
                        <Button onClick={reset}>Schließen</Button>
                    </Box>}
                </Box>
            }
        </Paper>
    );
};


const ShowTransactions = ({ posConfig, kdnr }) => {


    const [data, setData] = useState();

    const [newId, setNewId] = useState();

    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [lastRequestId, setLastRequestId] = useState(0)

    const [status, setStatus] = useState('open');

    useEffect(() => {
        setData();
        setStatus('open');
        if (kdnr) {
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId)
        }
    }, [kdnr]);


    useEffect(() => {
        setData();
        if (kdnr) {
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId)
        }
    }, [status]);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: posConfig.connection.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)

        if (msg.task === 'respGetSeparateEscrowAccount') {

            const {records, requestId } = msg;

            if (requestId === lastRequestId) {
                setData({  records });
            };

        };

        if (msg.task === 'respPutSeparateEscrowAccountEntry') {
            const { id, pdfUrl, notificationSent } = msg;
            const thisRqId = uuid();
            setLastRequestId(thisRqId);
            getSeparateEscrowAccount(thisRqId);
            setNewId(pdfUrl || notificationSent);
            if (pdfUrl) {
                window.open(pdfUrl);
            }
        };

        if (msg.task === 'respPutSeparateEscrowAccountDeposit') {
            const {records} = msg;
            setData({ records });
        }
        

    };

    const getSeparateEscrowAccount = (thisRqId) => {
        sendMessage(JSON.stringify({ task: 'getSeparateEscrowAccount', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, kdnr, status }))
    }

    const putSeparateEscrowAccountEntry = ({ thisRqId, variant, status, type, comment, amount, flow }) => {
        const x = JSON.stringify({ task: 'putSeparateEscrowAccountEntry', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId, kdnr, status, type, user: cookies.staff, comment, amount, variant, flow });
        console.log({ x })
        sendMessage(x)
    }

    const resetHandler = () => {
        setNewId();
        setData();
    }

    return (

        <Paper sx={{ p: 4, mb: 2 }} variant="outlined" square>
            <Select value={status} onChange={(evt) => setStatus(evt.target.value)} sx={{ mb: 4 }}>
                <MenuItem value="open">Offene Vorgänge</MenuItem>
                <MenuItem value="closed">Geschloßene Vorgänge</MenuItem>
            </Select>

            {data && <>

           

                <Typography variant="h6">Buchungen</Typography>
                <Box mb={4}>
                    {data?.records?.length > 0 && 
                        <RecordsListSimple records={data.records.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))} />
                    }
                    { ! data?.records?.length && <Typography variant="caption" gutterBottom>Keine Einlagen</Typography>}
                </Box>

            </>}


        </Paper>


    );
};


const AuslagenWidget = ({ posConfig, kdnr }) => {


    return (
        <>
            <ShowTransactions kdnr={kdnr} posConfig={posConfig} />
            <AddDeposit kdnr={kdnr} posConfig={posConfig} />
        </>
    );
};
export { AuslagenWidget };



/*

Mangento => CC aus CH dann storniert der Shop die CC Zahlung und ändert auf Nachnahme

Zoll / Nachnahme / Portonachzahlung 

// Kdnr eingeben
// Maske "Neue Einlage"
// Betrag, Sendung, Datum, MA in DB
// optional: eQuittung


// Maske "Neue Auslage"
// Kdnr eingeben
// Prüfe DB nach Einträgen - Liste 
=> Weiter:
// Erzeugt DB Eintrag
// Erzeugt PDF
=>> Nicht weiter:
Email an Kunde, mit Betrag und Sendungsnummer


Bei Sendungsabholung:


optional: eQuittung


*/


const formatCurrency = (amount) => {
    return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount / 100)
};