import React, {
    useState,
    useEffect,
} from 'react'

import {
    Box,
    Button,
    Collapse,
    TextField,
    Alert,
    Typography,
    Card,
} from  '@mui/material'

const CreditManagementLight = ({addFunds, calculateBonus, respCalculateBonus, respAddFunds, getCustomerTransactions}) => {

    const [open, setOpen] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);

    const init = {
        receiptIdString: '',
        amount: 0,
        code: ''
    }
    const [newFund, setNewFund] = useState(init);

    const [bonus, setBonus] = useState(false)

    const checkBonusHandler = () => {
        setBonus(false)
        setIsLoading(true)
        calculateBonus(newFund.amount, newFund.code)
    }

    useEffect(()=>{
        setIsLoading(false)
        setBonus(respCalculateBonus)
    },[respCalculateBonus])

    useEffect(()=>{
        console.log({respAddFunds})
        if(respAddFunds && "succeeded" in respAddFunds){
            if(respAddFunds.succeeded === true){
                setNewFund(init)
                setBonus(false)
                setValidationErrors([])
                setOpen(false)
                getCustomerTransactions()
            }
        }
    },[respAddFunds])

    const submitHandler = () => {
        const err = []
        if(newFund.amount<1 || newFund.amount>5000) err.push('Betrag muss zwischen 1 und 5000 Euro liegen')
        if(newFund.receiptIdString.length<10) err.push('Quittungs-Nummer fehlt')
        if(err.length){
            setValidationErrors(err)
        }else{
            addFunds(newFund)
        }
    }

    return (
        <>
            <Button variant="outlined" color="success" onClick={()=>setOpen(true)}>Guthaben aufladen</Button>

            <Collapse in={open}>
                <Card sx={{p:2, mt:2}} elevation={3}>

                    <Alert severity="info" sx={{my:2}}>
                        Zuerst den Betrag in Bar oder per Karte kassieren, danach den Betrag aufladen!
                    </Alert>
                    <Box sx={{mb:2}}>
                        <TextField label="Quittungs-Nummer" value={newFund.receiptIdString} onChange={(evt)=>setNewFund({...newFund, receiptIdString: evt.target.value})} />
                    </Box>
                    <Box sx={{mb:2}}>
                        <TextField label="Betrag in Euro" type="number" value={newFund.amount} onChange={(evt)=>setNewFund({...newFund, amount: evt.target.value})} /> 
                    </Box>
                    <Box sx={{mb:2}}>
                        <TextField label="Bonus-Code" value={newFund.code} onBlur={checkBonusHandler} onChange={(evt)=>setNewFund({...newFund, code: evt.target.value})} /> <Button disabled={isLoading} onClick={checkBonusHandler}>Code prüfen</Button>
                    </Box>
                    
                    {bonus && 
                        <>
                            { bonus.couponValid && bonus?.isFreeCredit && 
                                <Alert severity="warning" sx={{mb:2}}>
                                    Dieser Code muss unter Gutschein-Code eingelöst werden. Dieser Code ist hier nicht gültig.<br />
                                </Alert>
                            }
                            { bonus.couponValid && bonus?.isFreeCredit !== true &&
                                <Alert severity="success" sx={{mb:2}}>
                                    Gutschein-Code gültig<br />
                                    Gratis Bonus-Guthaben: {bonus.bonus/100} €<br />
                                </Alert>
                            }
                            { ! bonus.couponValid && newFund?.code?.length > 0 && 
                                <Alert severity="error" sx={{mb:2}}>
                                    Gutschein-Code nicht gültig
                                </Alert>
                            }
                        </>
                    }
                        
                    
                    { validationErrors.length > 0 &&
                        <Alert severity="error" sx={{mb:2}}>
                            Fehler:<br />
                            {validationErrors.map((e,i)=>{
                                return <Typography key={i}>{e}</Typography>
                            })}
                        </Alert>
                    }
                    <Box>
                        <Button disabled={isLoading || bonus?.isFreeCredit} onClick={()=>{ setIsLoading(true) ;submitHandler()}} variant="outlined">Betrag aufladen</Button>
                    </Box>
                </Card>
            </Collapse>

        </>
    )
}

export default CreditManagementLight