import { LinearProgressWithLabel, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Card, Box, Chip, Badge, Avatar, Divider, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import React, {
  useState,
  useEffect,
} from 'react'
import { useCookies } from 'react-cookie';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import uuid from 'react-uuid';
import { ConnectedTv, KeyboardArrowDown, Refresh } from '@mui/icons-material';
import { ShipmentHistoryButton } from './Checkout';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const OptimizeWarehouse = ({ posConfig }) => {
  const [temp, setTemp] = useState("");
  const [search, setSearch] = useState(false)

  const [loading, setLoading] = useState(false)

  const [sortDir, setSortDir] = useState('ASC');
  const [sortKey, setSortKey] = useState(false);

  const handleSort = (key) => {
    if (key === sortKey) {
      setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC')
    } else {
      setSortKey(key)
      setSortDir('ASC')
    }

  }
  const [lastRequestId, setLastRequestId] = useState(0)
  const [searchResults, setSearchResults] = useState([])
  const [customerResults, setCustomerResults] = useState([])

  const [overrides, setOverrides] = useState({});

  const [cookies] = useCookies(['secret', 'station', 'staff'])

  const { sendMessage, readyState } = useWebSocket(socketUrl,
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
        staff: cookies.staff
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );

  const handleWebsocketEvent = (lastMessage) => {

    const msg = JSON.parse(lastMessage.data)

    if (msg.task === 'respClutteredShipments') {
      const { results, customers, requestId } = msg

      if (requestId === lastRequestId) {
        setLoading(false)
        setSearchResults(results)
        setCustomerResults(customers);
      }

    }
    if(msg.task === 'respClutteredShipmentsByKdnr'){

      const { results } = msg;
      setOverrides({...overrides, [results[0].kdnr]: results[0]});

    }

  };

  const getClutteredShipments = (thisRqId) => {
    sendMessage(JSON.stringify({ task: 'getClutteredShipments', station: posConfig.connection.station, staff: cookies.staff, requestId: thisRqId }))
  };

  const pickupMonitorMessage = (customerId, customerName, unclutterList) => {
    sendMessage(JSON.stringify({task: 'pickupMonitorMessage', station: posConfig.connection.station, staff: cookies.staff, customerId, customerName, unclutterList, pickupList: []}))
  }

  const refreshDataRow = (kdnr) => {
    sendMessage(JSON.stringify({ task: 'getClutteredShipmentsByKdnr', station: posConfig.connection.station, staff: cookies.staff, kdnr }))
  }


  useEffect(() => {
    const thisRqId = uuid()
    setLastRequestId(thisRqId)
    getClutteredShipments(thisRqId)
  }, []);

  const keys = [
    'Kundennummer',
    'Sendungen'
  ];

  const customerData = customerResults.reduce((p,c)=>{
    if(c.user_id in p === false){
      p[c.user_id] = {[c.meta_key]: c.meta_value};
    }else{
      p[c.user_id][c.meta_key] = c.meta_value;
    }
    return p;
  },{});

  const tableData = searchResults.map(e => {
    return {
      kdnr: e.kdnr,
      firstname: customerData[e.kdnr].first_name,
      lastname: customerData[e.kdnr].last_name,
      locationCount: e.unique_lager_count,
      storageSlots: Object.values((overrides[e.kdnr] ? overrides[e.kdnr].data : e.data).split(',').map(ee => {
        const [mpsid, storageName, klasse] = ee.split('=');
        return {
          mpsid,
          storageName,
          klasse
        }
      }).reduce((p, c) => {
        if (p[c.storageName]?.shipments) {
          p[c.storageName].shipments.push(c)
        } else {
          p[c.storageName] = { shipments: [c] };
          p[c.storageName].storageName = c.storageName;
        }
        return p;
      }, {}))
    };
  }).sort((a, b) => b.locationCount - a.locationCount);

// B Regal = 6m
// Filter Paketklassen
// Button => Bildschirm
// Button => weg-X-en


  return (
    <>
      <Typography variant="h3" gutterBottom>Lageroptimierung</Typography>

      {loading && <LinearProgress />}

      {!!searchResults.length &&
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {keys.map((e, i) => {
                  return <TableCell key={i}>{e}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((e, i) => {
                return <DataTableRow row={e} key={i} pickupMonitorMessage={pickupMonitorMessage} refreshDataRow={refreshDataRow} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  )
}

export default OptimizeWarehouse

/*

*/



const DataTableRow = ({row, pickupMonitorMessage, refreshDataRow}) => {

  const [open, setOpen] = useState(true);
  
  const picklist = row;

  const closeHandler = () => {
    pickupMonitorMessage(row.kdnr, row.firstname + " " + row.lastname, false);
    setOpen(false)
  };

  return (<>
    <TableRow sx={{display: ! open ? 'table-row' : 'none'}}>
      <TableCell colSpan={2}>
        <IconButton onClick={()=>setOpen(true)}>
          <KeyboardArrowDown />
        </IconButton>
        {row.kdnr} {row.firstname} <b>{row.lastname}</b>
      </TableCell>
    </TableRow>
    <TableRow sx={{display: open ? 'table-row' : 'none'}}>
      <TableCell sx={{width: '400px', verticalAlign: 'top'}}>
        <Box sx={{display: 'flex', gap: 2, mt: 2}}>
          <Badge color='warning' badgeContent={row.storageSlots.reduce((p,c)=>{
            p=p+c.shipments.length;
            return p;
          },0)}>
            <Avatar /> 
          </Badge>
          <Typography variant="h6">{row.firstname} <b>{row.lastname}</b></Typography>
        </Box>
        <Typography variant="h3" gutterBottom>{row.kdnr}</Typography>
        <Box sx={{display:'flex', gap:2}}>
          <IconButton variant='contained' onClick={()=>refreshDataRow(row.kdnr)}><Refresh /></IconButton>
          <Button variant="contained" startIcon={<ConnectedTv />} onClick={()=>pickupMonitorMessage(row.kdnr, row.firstname + " " + row.lastname, picklist)}>Anzeigen</Button>
          <Button variant="outlined" onClick={closeHandler}>Schließen</Button>
        </Box>
      </TableCell>
      <TableCell>
        <Divider><Typography variant="caption">{row.locationCount} Lagerplätze</Typography></Divider>
        {row.storageSlots.map((ee, ii) => {
          return (
            <Card key={ii} sx={{ p: 2, my: 1 }}>
              <Typography variant="h4">{ee.storageName}</Typography>
              {ee.shipments.map((shipment,iii)=>{
                return (
                  <Box key={iii}>
                    <Chip label={shipment.klasse} sx={{width:'50px', mb: 1}}/> {shipment.mpsid} <ShipmentHistoryButton id={shipment.mpsid} />
                  </Box>
                );
              })}
              {ee?.shipments?.length>2 && <Typography variant="caption" gutterBottom>{ee?.shipments?.length} Sendungen</Typography>}
            </Card>
          );
        })}
      </TableCell>
    </TableRow>
  </>)
}


